import { connect } from 'react-redux';
import ManageStaff from '../../views/Personnel/ViewStaff.personnel.view';
import { manageStaff, getStaffById } from '../../states/thunks/personnel.thunk';
import {
  getAgamaOptions, getGolonganDarahOptions, getStaffJabatanOptions, 
  getJenjangPendidikanOptions, getRegionOptions, getAktifNonAktifOptions,
  getPangkatGolonganOptions, getStatusKepegawaianOptions, getPTKPOptions,
  getUnitKerjaOptions
} from '../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    unitkerja: state.options.unitkerja,
    ptkp:state.options.ptkp,
    jenjangpendidikan: state.options.jenjangpendidikan,
    region: state.options.region,
    agama: state.options.agama,
    jabatan: state.options.jabatan,
    golongan: state.options.golongan,
    aktifnonaktif: state.options.aktifnonaktif,
    statuskepegawaian: state.options.statuskepegawaian,
    golongandarah: state.options.golongandarah,    
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleManageStaff: (payload, goBack, isEdit = false) => (dispatch(manageStaff(payload, goBack, isEdit))),
    handleGetStaffById: params => dispatch(getStaffById(params)),
    handleGetPTKPOptions: payload => dispatch(getPTKPOptions(payload)),
    handleGetAgamaOptions: payload => dispatch(getAgamaOptions(payload)),
    handleGetAktifNonAktifOptions: payload => dispatch(getAktifNonAktifOptions(payload)),
    handleGetPangkatGolonganOptions: payload => dispatch(getPangkatGolonganOptions(payload)),
    handleGetStatusKepegawaianOptions: payload => dispatch(getStatusKepegawaianOptions(payload)),
    handleGetUnitKerjaOptions: payload => dispatch(getUnitKerjaOptions(payload)),
    handleGetJenjangPendidikanOptions: payload => dispatch(getJenjangPendidikanOptions(payload)),
    handleGetRegionOptions: payload => dispatch(getRegionOptions(payload)),
    handleGetStaffJabatanOptions: payload => dispatch(getStaffJabatanOptions(payload)),
    handleGetGolonganDarah : payload => dispatch(getGolonganDarahOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageStaff);
