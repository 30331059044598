import { connect } from 'react-redux';
import ManageAdjustmentJournal from '../../../../views/Finance/Bookkeeping/AdjustmentJournal/ManageAdjustmentJournal.bookkeeping.view';
import { getAdjustmentJournalById, manageAdjustmentJournal } from '../../../../states/thunks/finance.thunk';
import { getCoaOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getCoaOptions(payload)),
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournalById(payload)),
    handleManageAdjustmentJournal: (payload, goBack) => (
      dispatch(manageAdjustmentJournal(payload, goBack))
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdjustmentJournal);
