import axios from 'axios';
import { map } from 'lodash';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';

const { CancelToken } = axios;
let source;

export function getBudgetOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS({
        ...payload,
        unit_id,
      }, '/budget');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'budget',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPeriodeOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/periode');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'periode',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getExistingPeriodeOptions() {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS({ unit_id }, '/existing-periode');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'periode',
        data: res.data,
      }));

      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getCoaOptions(payload = {}) {
  return (dispatch) => {
    if (source) {
      source();
    }
    dispatch(commonActions.getOptionsRequested());
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };
    api.OPTIONS(payload, '/code-of-account', options)
      .then((res) => {
        if (res) {
          dispatch(commonActions.getOptionsSucceeded({
            entity: 'coa',
            data: res.data,
          }));
          return res.data;
        }
      })
      .catch(() => {
        dispatch(commonActions.getOptionsFailed());
      });
  };
}

export function getRapbuCoa(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/rapbu-coa');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'coa',
        data: res.data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getCodeGroupOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/code-group');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'code_group',
        data: res.data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getBankAccountOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/bank-account');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'bank_account',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getUnitOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/unit');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'unit',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPerwakilanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/perwakilan');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'perwakilan',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getFundRequestPeriodeOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/fund-request-periode');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'fundRequestPeriode',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getAgamaOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/agama');
      const data = map(res.result, (item) => {
        return {
          'label': item.name,
          // 'value': item.id, //kalu pakai ini pas di console log valuenya:"undefined" 

          'value': item.id_agama, //kalu pakai ini pas di console log valuenya:"ada isinya 1,2,3,4,5"
        }
      });
      dispatch(commonActions.getOptionsSucceeded({
        entity :'agama',
        data: data     
      }));
      // console.log("ini isi data agama")
      // console.log(data)
      return data;
      
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getMonthOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/month');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'month',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStaffJabatanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/jabatan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_jabatan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'jabatan',
        data,
      }));
      // console.log("ini isi data jabatan")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getGolonganDarahOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/golongandarah');
      const data = map(res.result, (item) =>{
        return{
          'label' : item.name,
          'value': item.id_golongan_darah,
        }
      });
      
      dispatch(commonActions.getOptionsSucceeded({
        entity:'golongandarah',
        data: data
      }));
      // console.log("ini isi data cuy")
      
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}

export function getRegionOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/region');
      const data = map(res.result, (item) =>{
        return{
          'label' : item.name,
          'value': item.id_region,
        }
      });
      dispatch(commonActions.getOptionsSucceeded({
        entity:'region',
        data: data
      }));
      // console.log("ini isi data cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}

export function getAktifNonAktifOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/aktifnonaktif');
      const data = map(res.result, (item) =>{
        return{
          'label' : item.name,
          'value': item.id_aktif_non_aktif,
        }
      });
      dispatch(commonActions.getOptionsSucceeded({
        entity:'aktifnonaktif',
        data: data
      }));
      // console.log("ini isi data cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}

export function getStatusKepegawaianOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/statuskepegawaian');
      const data = map(res.result, (item) =>{
        return{
          'label' : item.name,
          'value': item.id_status_kepegawaian,
        }
      });
      dispatch(commonActions.getOptionsSucceeded({
        entity:'statuskepegawaian',
        data: data
      }));
      // console.log("ini isi kepegawaian cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}

export function getUnitKerjaOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/unitkerja');
      const data = map(res.result, (item) =>{
        return{
          'label' : item.name,
          'value': item.id_unit_kerja,
        }
      });
      dispatch(commonActions.getOptionsSucceeded({
        entity:'unitkerja',
        data: data
      }));
      // console.log("ini isi unit kerja cuysdsdsdsdsdsds")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}


export function getPangkatGolonganOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/golongan');
      const data = map(res.result, (item) =>{
        if(res.message_id==="000"){
          return{
            'label': item.golongan + '-' + item.masa_kerja,
            'value': item.id_golongan
          }
        }else{
          return{
            'label': '',
            'value': ''
          }
        }
      });
      dispatch(commonActions.getOptionsSucceeded({
        entity:'golongan',
        data: data
      }));
      // console.log("ini isi unit Golongannnnnnnnnnn cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}


export function getJenjangPendidikanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/jenjangpendidikan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_jenjang_pendidikan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'jenjangpendidikan',
        data,
      }));
      // console.log("ini isi data csasasuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getJournalCoaOptions(payload = {}) {
  return async (dispatch) => {
    try {
      if (source) {
        source();
      }
      const options = {
        cancelToken: new CancelToken(((c) => {
          source = c;
        })),
      };
      dispatch(commonActions.setLoading({ show: true }));
      dispatch(commonActions.getOptionsRequested());
      const res = await api.FINANCE_JOURNAL_POST(payload, '/coa')
        .then((result) => {
          if (result) {
            dispatch(commonActions.getOptionsSucceeded({
              entity: 'coa',
              data: result.data,
            }));
          }
          return result;
        })
        .catch(() => {
          dispatch(commonActions.getOptionsFailed());
        });
        if(res) {
          return res.data;
        } else {
          return {};
        }
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPTKPOptions(payload = {}){
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/ptkp');
      const data = map(res.result, (item) => {
        return{
          'label' : item.keterangan,
          'value' : item.id_ptkp,
        }
      });
      dispatch(commonActions.getOptionsSucceeded({
        entity:'ptkp',
        data:data
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}
