import { connect } from "react-redux";
import ManagePayroll from "../../../views/Personnel/Payroll/ManagePayroll.payroll.view";
import {getPayrollData} from "../../../states/thunks/personnel.thunk";

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPayrollData: (payload) => dispatch(getPayrollData(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ManagePayroll);
