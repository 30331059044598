import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import Breadcrumb from "../Breadcrumb/Breadcrumb.component";
import yplLogo from "../../assets/img/Spin.png";
import language from "../../languages";

export default function Header(props) {
  const { pathname, onClickBreadcrumb } = props;
  return (
    <header className="admission-header">
      <div className="admission-header__top">
        <div className="admission-header__top-content">
          <button>
            <img
              className="admission-header__ypl-logo"
              src={yplLogo}
              alt="YPL Logo"
            />
          </button>
        </div>
      </div>
      <div className="admission-header__bottom">
        <div className="admission-header__bottom-content">
          <p className="admission-header__greeting">
            {language.translate.COMMON__WELCOME}
          </p>
          <Breadcrumb pathname={pathname} onClick={onClickBreadcrumb} />
        </div>
      </div>
    </header>
  );
}
Header.propTypes = {
  onClickBreadcrumb: PropTypes.func,
  pathname: PropTypes.string.isRequired,
};
Header.defaultProps = {
  onClickBreadcrumb: noop,
};
