import React, { useEffect, useState } from 'react';

export const AlertContext = React.createContext();

export default function AlertContextProvider({ children }) {
  const [alert, setAlert] = useState({});
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (alert.icon && alert.className && alert.message) {
      setVisible(true);
    }
    return () => setVisible(false);
  }, [alert]);

  const showAlert = (type, message) => {
    switch (type) {
      case 'success':
        setAlert({
          icon: 'icon-ok',
          className: 'alert-success',
          message,
        });
        break;
      case 'error':
        setAlert({
          icon: 'icon-attention-circled',
          className: 'alert-danger',
          message,
        });
        break;
      default:
        setAlert({
          icon: 'icon-attention',
          className: 'alert-warning',
          message,
        });
        break;
    }
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      { visible && (
        <div className={`alert ${alert.className}`} role="alert">
          <span className={`icon ${alert.icon}`}>{alert.message}</span>
          <button
            type="button"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setVisible(false)}
          >
            <span aria-hidden="true" className="icon icon-cancel" />
          </button>
        </div>
      )}
      { children }
    </AlertContext.Provider>
  );
}
