import { connect } from 'react-redux';
import BigBookReport from '../../../views/Finance/Report/BigBook.report.view';
import { getGeneralLedgerReport } from '../../../states/thunks/report.thunk';
import { getCoaOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getCoaOptions(payload)),
    handleGetGeneralLedgerReport: payload => dispatch(getGeneralLedgerReport(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BigBookReport);
