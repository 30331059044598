import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { commaFormatted } from '../../../../utils/transformer.util';
import { printJournal } from '../../../../utils/print.util';
import { SearchSelect, Button } from '../../../../components/base';

export default class BudgetRecaps extends Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.getUnitFilter = this.getUnitFilter.bind(this);
    this.getPerwakilanFilter = this.getPerwakilanFilter.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.state = {
      list: [],
      filters: {
        perwakilan: {},
        unit: {},
      },
    }

  }

  componentDidMount() {
    this.getData();
    this.getUnitFilter();
  }

  async getData() {
    const { filters } = this.state;
    const { location, handleGetBudgetRecaps } = this.props;
    const res = await handleGetBudgetRecaps({
      perwakilan_id: filters.perwakilan.id,
      unit_id: filters.unit.id,
    });

    this.setState(prevState => ({
      ...prevState,
      list: res,
    }));
  }

  async getUnitFilter(payload = null) {
    const { user, handleGetUnitOptions } = this.props;
    const { filters } = this.state;
    const { perwakilan } = filters;
    const { id = user.prm_perwakilan_id } = perwakilan;
    let newFilters = {};
    if(id !== null) {
      newFilters.prm_perwakilan_id = id;
    }
    const res = handleGetUnitOptions({
      ...payload,
      filters: newFilters
    });
    return res;
  }

  async getPerwakilanFilter(payload = null) {
    const { user, handleGetPerwakilanOptions } = this.props;
    const res = handleGetPerwakilanOptions(payload);
    return res;
  }

  onFilterChange(event) {
    const { target } = event;
    const { value, name } = target;
    let newFilters = {};
    if(name === 'perwakilan') {
      newFilters = {
        perwakilan: value,
        unit: {}
      };
    } else {
      newFilters = {
        [name]: value
      };
    }
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...newFilters,
      }
    }), () => {
      this.getData();
      if (name === 'perwakilan') {
        this.getUnitFilter();
      }
    });
  }

  renderFilter() {
    const { user, unit } = this.props;
    const { permissions } = user.user_group;
    const { filters } = this.state;

    if(permissions.includes('select_perwakilan')) {
      return (
        <div className="filter inline">
          <SearchSelect
            async
            name="perwakilan"
            label="Perwakilan"
            placeholder="Pilih Perwakilan"
            onSearch={this.getPerwakilanFilter}
            onClick={this.onFilterChange}
            value={filters.perwakilan}
            valueName="id"
            labelName="title"
          />
          <SearchSelect
            name="unit"
            label="Unit"
            placeholder="Pilih Unit"
            onSearch={this.getUnitFilter}
            onClick={this.onFilterChange}
            searchParams={{perwakilan_id: filters.perwakilan.id}}
            value={filters.unit}
            list={unit.list}
            valueName="id"
            labelName="title"
          />
        </div>
      );
    } else {
      return (
        <div className="filter">
          <SearchSelect
            async
            name="unit"
            label="Unit"
            placeholder="Pilih Unit"
            onSearch={this.getUnitFilter}
            onClick={this.onFilterChange}
            value={filters.unit}
            valueName="id"
            labelName="title"
          />
        </div>
      );
    }


  }

  renderHeader() {
    const cols = [];

    cols.push([
      <th colSpan={3}>URAIAN</th>,
      <th>PENDAPATAN</th>,
      <th>BEBAN</th>,
      <th>BELANJA INVENTARIS</th>,
      <th>LABA/RUGI</th>,
      <th>SALDO</th>,
    ]);
    return (
      <tr>
        {cols}
      </tr>
    );
  }

  renderRows() {
    const { list } = this.state;
    const rows = [];
    if(list['perwakilan']) {
      list['perwakilan'].forEach((perwakilan) => {
        const { units, name, total } = perwakilan;
        rows.push(
            <tr>
              <td className="sub_header" colSpan="8">
                <h3>{name}</h3>
              </td>
            </tr>
        );

        if(units) {
          units.forEach((unit) => {
            const { rapbs, apbs, tambahan, realisasi, rekomendasi } = unit;
            rows.push([
                <tr>
                  <td rowSpan="5"><div className="page-break-avoid">{unit.code}</div></td>
                  <td rowSpan="5"><div className="page-break-avoid">{unit.name}</div></td>
                  <td>RAPBU</td>
                  <td className="nominal">{commaFormatted(rapbs.pendapatan)}</td>
                  <td className="nominal">{commaFormatted(rapbs.beban)}</td>
                  <td className="nominal">{commaFormatted(rapbs.inventaris)}</td>
                  <td className="nominal">{commaFormatted(rapbs.labaRugi)}</td>
                  <td className="nominal">{commaFormatted(rapbs.saldo)}</td>
                </tr>
            ]);
            rows.push([
                <tr>
                  <td>REKOMENDASI</td>
                  <td className="nominal">{commaFormatted(rekomendasi.pendapatan)}</td>
                  <td className="nominal">{commaFormatted(rekomendasi.beban)}</td>
                  <td className="nominal">{commaFormatted(rekomendasi.inventaris)}</td>
                  <td className="nominal">{commaFormatted(rekomendasi.labaRugi)}</td>
                  <td className="nominal">{commaFormatted(rekomendasi.saldo)}</td>
                </tr>
            ]);
            rows.push([
                <tr>
                  <td>APBU</td>
                  <td className="nominal">{commaFormatted(apbs.pendapatan)}</td>
                  <td className="nominal">{commaFormatted(apbs.beban)}</td>
                  <td className="nominal">{commaFormatted(apbs.inventaris)}</td>
                  <td className="nominal">{commaFormatted(apbs.labaRugi)}</td>
                  <td className="nominal">{commaFormatted(apbs.saldo)}</td>
                </tr>
            ]);
            rows.push([
                <tr>
                  <td>TAMBAHAN</td>
                  <td className="nominal">{commaFormatted(tambahan.pendapatan)}</td>
                  <td className="nominal">{commaFormatted(tambahan.beban)}</td>
                  <td className="nominal">{commaFormatted(tambahan.inventaris)}</td>
                  <td className="nominal">{commaFormatted(tambahan.labaRugi)}</td>
                  <td className="nominal">{commaFormatted(tambahan.saldo)}</td>
                </tr>
            ]);
            rows.push([
                <tr>
                  <td>REALISASI</td>
                  <td className="nominal">{commaFormatted(realisasi.pendapatan)}</td>
                  <td className="nominal">{commaFormatted(realisasi.beban)}</td>
                  <td className="nominal">{commaFormatted(realisasi.inventaris)}</td>
                  <td className="nominal">{commaFormatted(realisasi.labaRugi)}</td>
                  <td className="nominal">{commaFormatted(realisasi.saldo)}</td>
                </tr>
            ]);
          });
        }

        if (total) {
          const { rapbs, apbs, tambahan, realisasi, rekomendasi } = total;
          rows.push([
              <tr className="total">
                <td colSpan="2" rowSpan="5"><div className="page-break-avoid">{`TOTAL ${name}`}</div></td>
                <td>RAPBU</td>
                <td className="nominal">{commaFormatted(rapbs.pendapatan)}</td>
                <td className="nominal">{commaFormatted(rapbs.beban)}</td>
                <td className="nominal">{commaFormatted(rapbs.inventaris)}</td>
                <td className="nominal">{commaFormatted(rapbs.labaRugi)}</td>
                <td className="nominal">{commaFormatted(rapbs.saldo)}</td>
              </tr>
          ]);
          rows.push([
              <tr className="total">
                <td>Rekomendasi</td>
                <td className="nominal">{commaFormatted(rekomendasi.pendapatan)}</td>
                <td className="nominal">{commaFormatted(rekomendasi.beban)}</td>
                <td className="nominal">{commaFormatted(rekomendasi.inventaris)}</td>
                <td className="nominal">{commaFormatted(rekomendasi.labaRugi)}</td>
                <td className="nominal">{commaFormatted(rekomendasi.saldo)}</td>
              </tr>
          ]);
          rows.push([
              <tr className="total">
                <td>APBU</td>
                <td className="nominal">{commaFormatted(apbs.pendapatan)}</td>
                <td className="nominal">{commaFormatted(apbs.beban)}</td>
                <td className="nominal">{commaFormatted(apbs.inventaris)}</td>
                <td className="nominal">{commaFormatted(apbs.labaRugi)}</td>
                <td className="nominal">{commaFormatted(apbs.saldo)}</td>
              </tr>
          ]);
          rows.push([
              <tr className="total">
                <td>TAMBAHAN</td>
                <td className="nominal">{commaFormatted(tambahan.pendapatan)}</td>
                <td className="nominal">{commaFormatted(tambahan.beban)}</td>
                <td className="nominal">{commaFormatted(tambahan.inventaris)}</td>
                <td className="nominal">{commaFormatted(tambahan.labaRugi)}</td>
                <td className="nominal">{commaFormatted(tambahan.saldo)}</td>
              </tr>
          ]);
          rows.push([
              <tr className="total">
                <td>REALISASI</td>
                <td className="nominal">{commaFormatted(realisasi.pendapatan)}</td>
                <td className="nominal">{commaFormatted(realisasi.beban)}</td>
                <td className="nominal">{commaFormatted(realisasi.inventaris)}</td>
                <td className="nominal">{commaFormatted(realisasi.labaRugi)}</td>
                <td className="nominal">{commaFormatted(realisasi.saldo)}</td>
              </tr>
          ]);
        }
      });
    }
    if(list['total']) {
      rows.push([
          <tr className="total">
            <td colSpan="2" rowSpan="5"><div className="page-break-avoid">{`TOTAL YPL`}</div></td>
            <td>RAPBU</td>
            <td className="nominal">{commaFormatted(list.total.rapbs.pendapatan)}</td>
            <td className="nominal">{commaFormatted(list.total.rapbs.beban)}</td>
            <td className="nominal">{commaFormatted(list.total.rapbs.inventaris)}</td>
            <td className="nominal">{commaFormatted(list.total.rapbs.labaRugi)}</td>
            <td className="nominal">{commaFormatted(list.total.rapbs.saldo)}</td>
          </tr>
      ]);
      rows.push([
          <tr className="total">
            <td>Rekomendasi</td>
            <td className="nominal">{commaFormatted(list.total.rekomendasi.pendapatan)}</td>
            <td className="nominal">{commaFormatted(list.total.rekomendasi.beban)}</td>
            <td className="nominal">{commaFormatted(list.total.rekomendasi.inventaris)}</td>
            <td className="nominal">{commaFormatted(list.total.rekomendasi.labaRugi)}</td>
            <td className="nominal">{commaFormatted(list.total.rekomendasi.saldo)}</td>
          </tr>
      ]);
      rows.push([
          <tr className="total">
            <td>APBU</td>
            <td className="nominal">{commaFormatted(list.total.apbs.pendapatan)}</td>
            <td className="nominal">{commaFormatted(list.total.apbs.beban)}</td>
            <td className="nominal">{commaFormatted(list.total.apbs.inventaris)}</td>
            <td className="nominal">{commaFormatted(list.total.apbs.labaRugi)}</td>
            <td className="nominal">{commaFormatted(list.total.apbs.saldo)}</td>
          </tr>
      ]);
      rows.push([
          <tr className="total">
            <td>TAMBAHAN</td>
            <td className="nominal">{commaFormatted(list.total.tambahan.pendapatan)}</td>
            <td className="nominal">{commaFormatted(list.total.tambahan.beban)}</td>
            <td className="nominal">{commaFormatted(list.total.tambahan.inventaris)}</td>
            <td className="nominal">{commaFormatted(list.total.tambahan.labaRugi)}</td>
            <td className="nominal">{commaFormatted(list.total.tambahan.saldo)}</td>
          </tr>
      ]);
      rows.push([
          <tr className="total">
            <td>REALISASI</td>
            <td className="nominal">{commaFormatted(list.total.realisasi.pendapatan)}</td>
            <td className="nominal">{commaFormatted(list.total.realisasi.beban)}</td>
            <td className="nominal">{commaFormatted(list.total.realisasi.inventaris)}</td>
            <td className="nominal">{commaFormatted(list.total.realisasi.labaRugi)}</td>
            <td className="nominal">{commaFormatted(list.total.realisasi.saldo)}</td>
          </tr>
      ]);
    }

    return rows;
  }
  render() {
    return(
      <div className="content_wrapper">
        <div className="table_title">
          <h1>REKAP RAPBU, PERSETUJUAN DAN REALISASI</h1>
          <h1>TAHUN 2020</h1>
          <h2>YAYASAN PANGUDI LUHUR</h2>
        </div>
        <div className="print">
          <Button
            title="Cetak"
            onClick={() => { printJournal('table_title', 'table', '', {
                pageSize: 'A4 portrait',
                printHeader: {
                  repeated: false,
                }
              });
          }}
          />
        </div>
        {this.renderFilter()}
        <table className="table">
          <thead>{this.renderHeader()}</thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </div>
    );
  }
}

BudgetRecaps.propTypes = {
  location: PropTypes.object.isRequired,
  handleGetBudgetRecaps: PropTypes.func,
  handleGetPerwakilanOptions: PropTypes.func,
  handleGetUnitOptions: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  unit: PropTypes.object,
  perwakilan: PropTypes.object,
}

BudgetRecaps.defaultProps = {
  handleGetBudgetRecaps: noop,
  handleGetPerwakilanOptions: noop,
  handleGetUnitOptions: noop,
  history: noop,
  user: noop,
  unit: {},
  perwakilan: {},
}
