import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import AdjustmentJournalForm from './components/AdjustmentJournalForm.component';
import { commaFormatted } from '../../../../utils/transformer.util';
import { FINANCE_ADJUSTMENT_JOURNAL_FORM_FIELDS } from '../../../../constants/finance/bookeeping.constant';
import Button from '../../../../components/base/Button/Button.component';

export default class ManageAdjustmentJournal extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      form: {
        value: {
          details: [],
        },
        error: {
          details: [],
        },
      },
    };
  }

  componentDidMount() {
    const { handleGetCoa, location } = this.props;
    const { isEdit = false, data } = location.state;
    if (isEdit) {
      this._setForm(data.id);
    }
    handleGetCoa();
  }

  async _setForm(id) {
    const { handleGetAdjustmentJournal } = this.props;
    try {
      const payload = await handleGetAdjustmentJournal({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            journal_number: payload.journal_number,
            date: payload.date,
            details: map(payload.details, detail => ({
              code_of_account: {
                code: detail.parameter_code.code,
                title: detail.parameter_code.title,
                type: 'account',
              },
              description: detail.description,
              nominal: commaFormatted(detail.nominal),
            })),
          },
          error: {
            details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onAddList(fieldName) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {},
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  _onDeleteList(idx, fieldName) {
    this.setState((prevState) => {
      const list = prevState.form.value[fieldName];
      const listError = prevState.form.error[fieldName];
      list.splice(idx, 1);
      return {
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [...list],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [...listError],
          },
        },
      };
    });
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleManageAdjustmentJournal,
      history,
    } = this.props;
    handleManageAdjustmentJournal(form.value, history.goBack);
  }

  render() {
    const { form } = this.state;
    const { coa } = this.props;
    const { details } = form.value;
    const { details: detailsErrors } = form.error;
    return (
      <div className="manage-adjustment-journal">
        <form onSubmit={this._onSubmit}>
          <ListForm
            form={form}
            formFields={FINANCE_ADJUSTMENT_JOURNAL_FORM_FIELDS}
            onFormChange={this._onFormChange}
          >
            <AdjustmentJournalForm
              coa={coa}
              fieldName="details"
              error={detailsErrors}
              list={details}
              onAddList={this._onAddList}
              onDeleteList={this._onDeleteList}
              onChange={this._onFormChange}
            />
          </ListForm>
          <div className="manage-adjustment-journal">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
ManageAdjustmentJournal.propTypes = {
  handleManageAdjustmentJournal: PropTypes.func,
  handleGetAdjustmentJournal: PropTypes.func,
  handleGetCoa: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
};
ManageAdjustmentJournal.defaultProps = {
  handleManageAdjustmentJournal: noop,
  handleGetAdjustmentJournal: noop,
};
