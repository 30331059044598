import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, findIndex, isEqual } from "lodash";
import language from "../../languages";
import { Pagination, Loading, Button } from "../base";
import { formatData } from "../../../src/utils/transformer.util";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class ContentTablePayrollList extends Component {
  constructor(props) {
    super(props);
    this.state = { content: initialContent, listAmount: 5 };
    this.isMount = false;
  }

  componentDidMount() {
    this.isMount = true;
    const { content } = this.state;
    const { initialParams } = this.props;
    if (isEmpty(content.list)) {
      this.onSearchContent({ page: 1, ...initialParams });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.isMount = false;
  }

  onSearchContent = (params) => {
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        const { onSearchContent } = this.props;
        try {
          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = await onSearchContent(params);
          if (this.isMount) {
            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
              },
            });
          }
        } catch (err) {
          if (this.isMount) {
            this.setState({
              content: {
                loading: false,
                list: [],
                currentPage: 1,
                total: 1,
                totalPage: 1,
                error: true,
              },
            });
          }
        }
      }
    );
  };

  onChangeListAmount = (event) => {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this.onSearchContent({ limit: event.target.value, keywords, page: 1 });
  };

  onChangePage = (page) => {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  };

  //payroll view
  onClickView = async (id) => {
    const { onClickView } = this.props;
    onClickView(id);
  };

  //slip payroll
  onClickInputSlip = (id) => {
    const { onClickInputSlip } = this.props;
    onClickInputSlip(id);
  };

  onClickPreviewSlip = (id,bulan,tahun) => {
    const { onClickPreviewSlip } = this.props;
    onClickPreviewSlip(id,bulan,tahun);
  };

  render() {
    const { listAmount, content } = this.state;
    const { tableFields, process } = this.props;
    const loadingProps = { show: content.loading };

    return (
      <div className="content-table">
        {content.loading ? (
          <Loading loading={loadingProps} />
        ) : (
          <table className="table">
            <thead>
              <tr>
                {map(tableFields.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {map(content.list, (list, idx) => (
                <tr key={`user_management_tr_${idx}`}>
                  {map(tableFields.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}>
                          {idx + 1 + (content.currentPage - 1) * listAmount}
                        </td>
                      );
                    }
                    return (
                      <td key={`table_${fieldIdx}_${idx}`}>
                        {formatData(list, field)}
                      </td>
                    );
                  })}
                  <td>
                    <div className="table__actions">
                      {process === "view" ? (
                        <>
                          <Button
                            onClick={() => this.onClickView(list.id_payroll)}
                            title="View"
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() =>
                              this.onClickInputSlip(list.id_payroll)
                            }
                            title="Input"
                          />
                          <Button
                            onClick={() =>
                              this.onClickPreviewSlip(list.id_payroll,list.month,list.year)
                            }
                            title="Preview"
                          />
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
          </p>
          <Pagination
            totalPage={content.totalPage}
            currentPage={content.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}

ContentTablePayrollList.propTypes = {
  onClickView: PropTypes.func,
  onClickInputSlip: PropTypes.func,
  onClickPreviewSlip: PropTypes.func,
};

ContentTablePayrollList.defaultProps = {
  onClickView: noop,
  onClickInputSlip: noop,
  onClickPreviewSlip: noop,
  initialParams: {},
};
