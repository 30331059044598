import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Input } from '../../components/base';


export default class ManagePassword extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      form: {
        value: {
          oldPassword: '',
          newPassword: '',
          newPassword_confirmation: '',
        }
      },
      error: {}
    };
  }

  onChange(event) {
    const { target } = event;
    const { value, name } = target;
    const { form, error } = this.state;
    const { newPassword, newPassword_confirmation } = form.value;
    let isValid = true;
    let newError = { ...error };

    if(name === 'newPassword') {
      isValid = this.validatePasswordLength(value);
      if(isValid === true) {
        delete newError[name];
        if(newPassword_confirmation > 0) {
          isValid = this.validatePasswordMatch(value, newPassword_confirmation);
          if(isValid !== true) {
            newError.newPassword_confirmation = isValid;
          } else {
            delete newError.newPassword_confirmation
          }
        }
      } else {
        newError[name] = isValid;
      }
    } else if(name === 'newPassword_confirmation') {
      isValid = this.validatePasswordMatch(newPassword, value);
      if(isValid !== true) {
        newError[name] = isValid;
      } else {
        delete newError[name];
      }
    }

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value
        }
      },
      error: { ...newError }
    }));
  }

  validatePasswordMatch(newPassword, newPassword_confirmation) {
    if(newPassword.toString() === newPassword_confirmation.toString()) {
      return true;
    }
    return 'Password tidak sesuai';
  }

  validatePasswordLength(newPassword) {
    if(newPassword.length >= 6) {
      return true;
    }

    return 'Panjang minimal password harus 6 karakter';
  }

  onSubmit(event) {
    const { history, handleChangePassword } = this.props;
    const { error, form } = this.state;
    event.preventDefault();
    if(Object.keys(error).length === 0) {;
      handleChangePassword(form.value, () => {
        history.push('/login');
      });
    }
  }
  render() {
    const { form, error } = this.state;
    return (
      <div className="manage-password">
        <h1>Ganti Kata Sandi</h1>
        <form>
          <Input
            type="password"
            label="Kata Sandi Lama"
            name="oldPassword"
            value={form.value.oldPassword}
            onChange={this.onChange}
          />
          <Input
            type="password"
            label="Kata Sandi Baru"
            name="newPassword"
            value={form.value.newPassword}
            error={error ? error.newPassword : null}
            onBlur={this.onChange}
          />
          <Input
            type="password"
            label="Ulangi Kata Sandi Baru"
            name="newPassword_confirmation"
            value={form.value.newPassword_confirmation}
            error={error ? error.newPassword_confirmation : null}
            onBlur={this.onChange}
          />
          <Button
            type="submit"
            title="Submit"
            onClick={this.onSubmit}
          />
        </form>
      </div>
    )
  }
}

ManagePassword.propTypes = {
  handleChangePassword: PropTypes.func,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
};
ManagePassword.defaultProps = {
  handleGetBudgetDetail: noop,
  user: null,
  history: null,
};
