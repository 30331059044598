import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import ContentTablePayroll from '../../../components/ContentTable/ContentTablePayroll.component';
import { PERSONAL_PAYROLLLIST_TABLE_FIELDS } from '../../../constants/personnel.constant';
import { SearchSelect,Button} from '../../../../src/components/base';

export default class PayrollList extends PureComponent {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickView = this.onClickView.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.state = {
      list: [],
      filters: {
        nip: {},
        nama_lengkap: {},
      },
    }
    // console.log(this.state)
  }

  onFilterChange(event) {
    const { target } = event;
    const { value, name } = target;
    let newFilters = {};
    if(name === 'nip') {
      newFilters = {
        nip: value,
        unit: {}
      };
    } else {
      newFilters = {
        [name]: value
      };
    }
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...newFilters,
      }
    }), () => {
      if (name === 'nip') {
        this.getFilterPayroll();
      }
    });
  }

  // componentDidMount(){
  //   this.getData();
  //   this.getFilterPayroll();
  // }

  async getData() {
    const { filters } = this.state;
    const { location, handleGetPayroll } = this.props;
    const res = await handleGetPayroll({
      nip: filters.nip,
      nama_lengkap: filters.unit,
    });
    // console.log("paload payroll bor gaji")
    console.log(res)
    console.log(filters.nip)

    this.setState(prevState => ({
      ...prevState,
      list: res,
    }));
    console.log(this.state)
  }
  

  async getFilterPayroll(){
    const { filters } = this.state;
    const { handleGetPayroll } = this.props;
    const res = await handleGetPayroll({
      nip: filters.nip,
      nama_lengkap: filters.nama_lengkap,
    });

    console.log(res)
    this.setState(prevState => ({
      ...prevState,
      list: res.result,
    }));

    
    console.log(this.state)

    // const { nip } = filters;
    // const { id = nip } = nip;
    // let newFilters = {};
    // if(id !== null) {
    //   newFilters.nip = id;
    // }

  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/personalia/data-payroll/tambah-payroll');
    // personalia/data-payroll/tambah-payroll`}
  }

  onClickView(val) {
    console.log(this.props)
    const { history } = this.props;
    history.push('/dashboard/personalia/data-payroll/kelola-payroll', { data: val, isEdit: true });
  }

  onClickEdit(val) {
    console.log(this.props)
    const { history } = this.props;
    history.push('/dashboard/personalia/data-payroll/kelola-payroll', { data: val, isEdit: true });
  }

  onFilterChange(event) {
    const { target } = event;
    const { value, name } = target;
    let newFilters = {};
    if(name === 'nip') {
      newFilters = {
        nip: value,
        unit: {}
      };
    } else {
      newFilters = {
        [name]: value
      };
    }
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...newFilters,
      }
    }), () => {
      this.getData();
      if (name === 'nip') {
        this.getFilterPayroll();
      }
    });
  }


  render() {
    const { handleGetPayroll, handleDeletePayroll } = this.props;
    const {filters} = this.state;
    return (
      
      <div className="payroll">
        <div className="payroll__content">
        <div className="filter inline">
          <SearchSelect
            async
            name="nip"
            label="NO G"
            placeholder="Pilih No G"
            onSearch={this.nip}
            onClick={this.onFilterChange}
            value={filters}
            valueName="id"
            labelName="title"
            list={filters.list}
          />
          <SearchSelect
            name="nama_lengkap"
            label="Nama Pegawai"
            placeholder="Pilih Nama Pegawai"
            onSearch={this.nama_lengkap}
            onClick={this.onFilterChange}
            value={filters.nama_lengkap}
            // list={unitkerja.list}
            valueName="value"
            labelName="label"
          />
          <div className="payroll__button">
            <Button
              title="Tambah Data Payroll"
              onClick={this.onClickAdd}
            />
          </div>
          <br/>
        </div>
        
          <ContentTablePayroll
            onSearchContent={handleGetPayroll}
            tableFields={PERSONAL_PAYROLLLIST_TABLE_FIELDS}
            onClickEdit={this.onClickEdit}
            onClickView={this.onClickView}
            onClickDelete={handleDeletePayroll}
          />
        </div>

      </div>
    );
  }
}
PayrollList.propTypes = {
  handleGetPayroll: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleDeletePayroll: PropTypes.func,
  //user: PropTypes.object,
};

PayrollList.defaultProps = {
  handleGetPayroll: noop,
  handleDeletePayroll: noop,
  //user: null,
};
