import language from "../../languages/index";

export const FINANCE_CASH_JOURNAL_FORM_FIELDS = [
  {
    type: "date",
    name: "date",
    label: "Tanggal",
    attribute: "date",
  },
  {
    type: "select",
    name: "type",
    data: [
      { label: "Kas Masuk", value: "KAS_MASUK" },
      { label: "Kas Keluar", value: "KAS_KELUAR" },
    ],
    label:
      language.translate
        .FINANCE__BOOKKEEPING__CASH_JOURNAL__TRANSACTION_TYPE_LABEL,
    placeholder: "Pilih tipe",
    attribute: "type",
  },
  {
    type: "text",
    name: "number",
    label: "No. Bukti",
    placeholder: "Diisi otomatis oleh sistem",
    disabled: true,
  },
  {
    type: "select",
    name: "pos",
    label: "Pos",
    placeholder: "Pilih Pos",
    attribute: "pos",
    data: [
      { label: "Yayasan", value: "ypl" },
      { label: "Internal", value: "intern" },
      { label: "Komite", value: "committee" },
      { label: "BOS", value: "bos" },
    ],
    disabled: false,
  },
  {
    type: "text",
    name: "submitted_by",
    label: "Diserahkan oleh",
    placeholder: "Isi diserahkan oleh",
  },
  {
    type: "text",
    name: "received_by",
    label: "Diterima oleh",
    placeholder: "Isi diterima oleh",
  },
  {
    type: "select",
    name: "tipe",
    data: [
      { label: "Standard", value: "1" },
      { label: "Rekonsiliasi", value: "2" },
    ],
    label: "Tipe",
    placeholder: "Pilih Tipe",
  },
  {
    type: "searchSelect",
    name: "unit",
    label: "Unit tujuan",
    placeholder: "Pilih Unit",
  },
];

export const FINANCE_CASH_JOURNAL_RECONCILIATION_FORM = {
  tableHead: ["No.", "Kode", "Keterangan", "Nominal"],
  name: [
    {},
    {
      type: "selectCoa",
      name: "code_of_account",
      label: "K Akun",
      placeholder: "Pilih kode akun",
    },
    {
      name: "description",
    },
    {
      type: "text",
      inputType: "number",
      name: "nominal",
    },
  ],
};

export const FINANCE_CASH_JOURNAL_STANDARD_FORM = {
  tableHead: ["No.", "Kode", "Keterangan", "Nominal"],
  name: [
    {},
    {
      type: "selectCoa",
      name: "code_of_account",
      label: "Kode Akun",
      placeholder: "Pilih kode akun",
    },
    {
      name: "description",
    },
    {
      type: "text",
      inputType: "number",
      name: "nominal",
    },
  ],
};

export const FINANCE_CASH_JOURNAL_TABLE_FIELDS = {
  label: ["No", "Tanggal", "Nomor Journal", "Nominal", ""],
  value: [
    {},
    { type: "date", attribute: "date" },
    { type: "string", attribute: "journal_number" },
    { type: "number", attribute: "nominal" },
  ],
};

export const FINANCE_BANK_JOURNAL_FORM_FIELDS = [
  {
    type: "date",
    name: "date",
    label: "Tanggal",
    attribute: "date",
  },
  {
    type: "select",
    name: "type",
    data: [
      { label: "Bank Masuk", value: "BANK_MASUK" },
      { label: "Bank Keluar", value: "BANK_KELUAR" },
    ],
    label:
      language.translate
        .FINANCE__BOOKKEEPING__BANK_JOURNAL__TRANSACTION_TYPE_LABEL,
    placeholder: "Pilih tipe",
    attribute: "type",
  },
  {
    type: "text",
    name: "number",
    label: "No. Bukti",
    placeholder: "Diisi otomatis oleh sistem",
    disabled: true,
  },
  {
    type: "select",
    name: "pos",
    label: "Pos",
    placeholder: "Pilih Pos",
    attribute: "pos",
    data: [
      { label: "Yayasan", value: "ypl" },
      { label: "Internal", value: "intern" },
      { label: "Komite", value: "committee" },
      { label: "BOS", value: "bos" },
    ],
    disabled: false,
  },
  {
    type: "text",
    name: "submitted_by",
    label: "Diserahkan oleh",
    placeholder: "Isi diserahkan oleh",
  },
  {
    type: "text",
    name: "received_by",
    label: "Diterima oleh",
    placeholder: "Isi diterima oleh",
  },
  {
    type: "searchSelect",
    name: "bank_account",
    label: "Rekening",
  },
  {
    type: "select",
    name: "tipe",
    data: [
      { label: "Standard", value: "1" },
      { label: "Rekonsiliasi", value: "2" },
    ],
    label: "Tipe",
    placeholder: "pilih tipe",
  },
  {
    type: "searchSelect",
    name: "unit",
    label: "Unit tujuan",
    placeholder: "Pilih Unit",
  },
];

export const FINANCE_BANK_JOURNAL_RECONCILIATION_FORM = {
  tableHead: ["No.", "Kode", "Keterangan", "Nominal", "Unit Lawan Transaksi"],
  name: [
    {},
    {
      type: "selectCoa",
      name: "code_of_account",
      label: "Kode Akun",
      placeholder: "Pilih kode akun",
    },
    {
      name: "description",
    },
    {
      type: "text",
      inputType: "number",
      name: "nominal",
    },
    {
      name: "transaction",
    },
  ],
};

export const FINANCE_BANK_JOURNAL_STANDARD_FORM = {
  tableHead: ["No.", "Kode", "Keterangan", "Nominal"],
  name: [
    {},
    {
      type: "searchSelect",
      name: "code_of_account",
      label: "Kode Akun",
      placeholder: "Pilih kode akun",
    },
    {
      name: "description",
    },
    {
      type: "text",
      inputType: "number",
      name: "nominal",
    },
  ],
};

export const FINANCE_BANK_JOURNAL_TABLE_FIELDS = {
  label: ["No", "Tanggal", "Nomor Journal", "Nominal", ""],
  value: [
    {},
    { type: "date", attribute: "date" },
    { type: "string", attribute: "journal_number" },
    { type: "number", attribute: "nominal" },
  ],
};
