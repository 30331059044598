import axios from 'axios';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import { map } from 'lodash';

const { CancelToken } = axios;
let source;

export function getDataDeskripsi(payload = {}){
    return async (dispatch) => {
        try{
          dispatch(commonActions.getOptionsRequested());
          const res = await api.PARAM(payload,'/skdescription');
          const data = map(res.result, (item) => {
            return {
              'label': item.name,
              'value': item.id,
            }
          });

          dispatch(commonActions.getOptionsSucceeded({
            entity :'skdescription',
            data: data     
          }));
          return data;
          
        } catch (err) {
          dispatch(commonActions.getOptionsFailed());
          throw err;
        }
      };
}

export function getDataPegawai(payload = {}){
  // console.log("masuk")
  return async (dispatch) => {
      try{
        dispatch(commonActions.getOptionsRequested());
        const res = await api.STAFF_POST(payload,'/view');
        return{
            result : res.result
        } 
      } catch (err) {
        dispatch(commonActions.getOptionsFailed());
        throw err;
      }
    };
}

export function getDataGolongan(payload = {}){
  return async (dispatch) => {
      try{
        dispatch(commonActions.getOptionsRequested());
        const res = await api.PARAM(payload,'/golongan');
        const data = map(res.result, (item) => {
          return {
            'label': item.pangkat +' / '+item.id_golongan,
            'value': item.id_golongan+","+item.gaji_pokok
          }
        });

        dispatch(commonActions.getOptionsSucceeded({
          entity :'golongan',
          data: data     
        }));
        return data;

      } catch (err) {
        dispatch(commonActions.getOptionsFailed());
        throw err;
      }
    };
}

export function getDataJabatan(payload = {}){
  return async (dispatch) => {
      try{
        dispatch(commonActions.getOptionsRequested());
        const res = await api.PARAM(payload,'/jabatan');
        const data = map(res.result, (item) => {
          return {
            'label': item.name,
            'value': item.id_jabatan,
          }
        });

        dispatch(commonActions.getOptionsSucceeded({
          entity :'jabatan',
          data: data     
        }));
        return data;

      } catch (err) {
        dispatch(commonActions.getOptionsFailed());
        throw err;
      }
    };
}

export function getDataSK(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.SK_POST(payload,'/view');
      return{
          result : res.result
      } 
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function submitSK(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.SK_POST(payload, '/submit_sk');
      return{
          result : res.result
      } 
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getDataListSK(params){
  return async () => {
    try {
      const { nip } = params;
      const res = await api.SK_POST({ nip }, '/list');
      //console.log(res)
      return {
        result: res.result,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };

  
}

