import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, keys } from "lodash";
import logo from "../../assets/img/Spin.png";
import { HOME_INFO } from "../../variables/home.variable";

class Home extends Component {
  render() {
    const { user } = this.props;
    const { school_unit, workingUnit = {}, perwakilan = {} } = user;
    let unitName = "";
    if (keys(workingUnit).length > 0) {
      unitName = workingUnit.title;
    } else if (keys(perwakilan).length > 0) {
      unitName = perwakilan.name;
    } else {
      unitName = school_unit ? school_unit.name : "";
    }

    return (
      <div className="home">
        <img className="home__logo" src={logo} alt="" />
        <div className="home__info">
          {map(HOME_INFO, (list, index) => (
            <div key={`home__info__${index}`} className="home__info-list">
              <p>{list.label}</p>
              {unitName !== "" && <p>: {unitName}</p>}
              {school_unit === null && <p></p>}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function App(props) {
  return <Home {...props} />;
}
