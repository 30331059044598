export default {
  ALERT__ARE_YOU_SURE: 'Apakah Anda Yakin?',
  ALERT__COMMON_TITLE: 'Oppss... Something Wrong',
  ALERT__COMMON_MESSAGE: 'Please try again',
  ALERT__DELETE_INFO: 'Setelah dihapus anda tidak dapat mengembalikannya lagi!',
  ALERT__OK_TO_CONTINUE: 'Klik OK untuk melanjutkan',

  COMMON__CANCEL: 'Batal',
  COMMON__LOGIN: 'Login',
  COMMON__OK: 'Ok',
  COMMON__SAVE: 'Simpan',
  COMMON__EDIT: 'Edit',
  COMMON__WELCOME: 'Selamat Datang',

  COMPONENT__CONTENT_TABLE__ENTRIES: 'list',
  COMPONENT__CONTENT_TABLE__OF: 'dari',
  COMPONENT__CONTENT_TABLE__PAGE: 'Halaman',
  COMPONENT__CONTENT_TABLE__SHOW: 'Tampilkan',

  FINANCE__BUDGETING__INCOME__TITLE: 'Pendapatan',
  FINANCE__BUDGETING__ADD_LINE__TITLE: 'Tambah Baris',

  FINANCE__BUDGETING__MANAGE_BUDGET__TITLE: 'Kelola Anggaran',
  FINANCE__BUDGETING__MANAGE_BUDGET__INCOME: 'Pendapatan',
  FINANCE__BUDGETING__MANAGE_BUDGET__OUTCOME: 'Pengeluaran',
  FINANCE__BUDGETING__MANAGE_BUDGET__RAPBU: 'RAPBU',

  FINANCE__BOOKKEEPING__CASH_JOURNAL__TRANSACTION_TYPE_LABEL: 'Jurnal Kas',
  FINANCE__BOOKKEEPING__CASH_JOURNAL__TRANSACTION_TYPE_PLACEHOLDER: 'Pilih jurnal kas',

  FINANCE__BOOKKEEPING__BANK_JOURNAL__TRANSACTION_TYPE_LABEL: 'Jurnal Bank',
  FINANCE__BOOKKEEPING__BANK_JOURNAL__TRANSACTION_TYPE_PLACEHOLDER: 'Pilih jurnal bank',

  HOME__INFO__LABEL_1: 'Unit Kerja',
  HOME__INFO__VALUE_1: 'Yayasan Pangudi Luhur Pusat',
  HOME__INFO__LABEL_2: 'Alamat',
  HOME__INFO__VALUE_2: 'Jl. Dr. Sutomo No. 4 Semarang 50244',
  HOME__INFO__LABEL_3: 'No. Telp',
  HOME__INFO__VALUE_3: '(024) 831 4004, 831 7806',
  HOME__INFO__LABEL_4: 'Fax',
  HOME__INFO__VALUE_4: '(024) 845 2372',
  HOME__INFO__LABEL_5: 'Website',
  HOME__INFO__VALUE_5: 'www.pangudiluhur.org',
  HOME__INFO__LABEL_6: 'Email',
  HOME__INFO__VALUE_6: 'plpusatsmg@gmail.com',

  ROUTE__DASHBOARD: 'Beranda',

  SIDEBAR__HOME: 'Beranda',
  SIDEBAR__UPLOAD: 'Upload',
  SIDEBAR__STUDENT: 'Kesiswaan',
  SIDEBAR__STUDENT__REGISTRATION: 'Pendaftaran',
  SIDEBAR__STUDENT__STUDENT_DATA: 'Data Siswa',
  SIDEBAR__STUDENT__STUDENT_REPORT: 'Laporan Kesiswaan',
  SIDEBAR__STUDENT__STUDENT_REPORT__NIS: 'Nomor Induk Siswa',
  SIDEBAR__STUDENT__STUDENT_REPORT__KLAPPER: 'Klapper',
  SIDEBAR__CURRICULUM: 'Kurikulum',
  SIDEBAR__PERSONAL: 'Personalia',
  SIDEBAR__PERSONAL__MANAGE_STAFF: 'Kolola Staff',
  SIDEBAR__PERSONAL__STAFF_LIST: 'Data Staff',
  SIDEBAR__PERSONAL__APPROVAL_SK: 'Approval SK',
  SIDEBAR__PERSONAL__PAYROLL_LIST: 'Data Payroll',
  SIDEBAR__PERSONAL__LETTER: 'Surat',
  SIDEBAR__PERSONAL__LETTER__INFORMATION_LETTER: 'Surat Keterangan',
  SIDEBAR__PERSONAL__LETTER__JOB_APPLICATION: 'Lamaran Kerja',
  SIDEBAR__PERSONAL__LETTER__REQUEST: 'Barang Jasa',
  SIDEBAR__PERSONAL__LETTER__DISCONTINUANCE: 'Pemberhentian',
  SIDEBAR__PERSONAL__LETTER__DISCONTINUANCE_DESCRIPTION: 'Keterangan Pemberhentian',
  SIDEBAR__PERSONAL__LETTER__ASSIGNMENT: 'Penugasan',
  SIDEBAR__PERSONAL__LETTER__RETIRE: 'Pensiun',
  SIDEBAR__PERSONAL__LETTER__PROMOTION: 'Kenaikan',
  SIDEBAR__PERSONAL__LETTER__EMPLOYEE_CONTRACT: 'Karyawan Kontrak',
  SIDEBAR__PERSONAL__LETTER__TEACHER_CONTRACT: 'Guru Kontrak',
  SIDEBAR__PERSONAL__LETTER__POSITION: 'Jabatan',
  SIDEBAR__PERSONAL__LETTER__PERMANENT: 'Pengangkatan',
  SIDEBAR__PERSONAL__REPORT: 'Laporan',
  SIDEBAR__PERSONAL__REPORT__JOB_PERIOD: 'Masa Kerja',
  SIDEBAR__PERSONAL__REPORT__TEACHER_EMPLOYEE: 'Guru Karyawan',
  SIDEBAR__FINANCE: 'Keuangan',
  SIDEBAR__FINANCE__BUDGETING: 'Penganggaran',
  SIDEBAR__FINANCE__BUDGETING__BUDGET: 'Anggaran',
  SIDEBAR__FINANCE__BUDGETING__BUDGET__INCOME: 'Pendapatan',
  SIDEBAR__FINANCE__BUDGETING__BUDGET__EXPENSE: 'Pengeluaran',
  SIDEBAR__FINANCE__BUDGETING__BUDGET__RAPBU: 'RAPBU',
  SIDEBAR__FINANCE__BUDGETING__NON_BUDGET: 'Non Anggaran',
  SIDEBAR__FINANCE__BUDGETING__BUDGET_TRANSFER: 'Pengalihan Anggaran',
  SIDEBAR__FINANCE__BUDGETING__FUND_REQUEST: 'Permohonan Dana',
  SIDEBAR__FINANCE__BUDGETING__REALIZATION: 'Realisasi',
  SIDEBAR__FINANCE__BOOKKEEPING: 'Pembukuan',
  SIDEBAR__FINANCE__BOOKKEEPING__CASH_JOURNAL: 'Jurnal Kas',
  SIDEBAR__FINANCE__BOOKKEEPING__BANK_JOURNAL: 'Jurnal Bank',
  SIDEBAR__FINANCE__BOOKKEEPING__PAYMENT_JOURNAL: 'Jurnal Pembayaran',
  SIDEBAR__FINANCE__BOOKKEEPING__ADJUSTMENT_JOURNAL: 'Jurnal Penyesuaian',
  SIDEBAR__FINANCE__BOOKKEEPING__CLOSING_JOURNAL: 'Jurnal Penutup',
  SIDEBAR__FINANCE__BOOKKEEPING__NON_KAS_JOURNAL: 'Jurnal Non Kas',
  SIDEBAR__FINANCE__REPORT: 'Laporan',
  SIDEBAR__FINANCE__REPORT__CASH_JOURNAL: 'Kas Harian',
  SIDEBAR__FINANCE__REPORT__BANK_JOURNAL: 'Bank Harian',
  SIDEBAR__FINANCE__REPORT__BIG: 'Buku Besar',
  SIDEBAR__FINANCE__REPORT__MONTH: 'Rekap Transaksi',
  SIDEBAR__FINANCE__REPORT__DAILY_MONTH: 'Bulanan',
  SIDEBAR__FINANCE__REPORT__PROFIT_AND_LOST: 'Aktivitas Keuangan',
  SIDEBAR__FINANCE__REPORT__BALANCE: 'Posisi Keuangan',
  SIDEBAR__ADMINISTRATION: 'Administrasi',
  SIDEBAR__INVENTORY: 'Inventaris',
  SIDEBAR__LIBRARY: 'Perpustakaan',
  SIDEBAR__SETTING: 'Pengaturan',
};
