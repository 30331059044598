import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import ReportCard from '../../../components/ReportCard/ReportCard.component';
import { commaFormatted, dateFormat } from '../../../utils/transformer.util';


export default class BigBookReport extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      coa: {
        from: null,
        to: null,
      },
      from: dateFormat(date, 'DD-MM-YYYY'),
      to: dateFormat(date, 'DD-MM-YYYY'),
      report: [],
    };
    this._onSearch = this._onSearch.bind(this);
    this._getReport = this._getReport.bind(this);
    this._onSearchCoa = this._onSearchCoa.bind(this);
  }

  componentDidMount() {
    const { handleGetCoa } = this.props;
    handleGetCoa();
    this._getReport();
  }

  componentDidUpdate(prevProps, prevState) {
    const { coa, from, to } = this.state;

    if (coa !== prevState.coa || from !== prevState.from || to !== prevState.to) {
      this._getReport();
    }
  }

  _onSearchCoa(val) {
    const { handleGetCoa } = this.props;
    handleGetCoa(val);
  }

  _onSearch(val) {
    const { from = '', to = '', coa = {} } = val;
    this.setState(prevState => ({
      ...prevState,
      from: from,
      to: to,
      coa: {
        from: (coa.from) ? coa.from.code : null,
        to: (coa.to) ? coa.to.code : null,
      }
    }));
  }

  async _getReport() {
    const { handleGetGeneralLedgerReport } = this.props;
    const { coa = {}, from, to } = this.state;
    if (coa.from !== null && coa.to !== null) {
      const res = await handleGetGeneralLedgerReport({
        from,
        to,
        coaFrom: coa.from || null,
        coaTo: coa.to || null,
      });

      this.setState(prevState => ({
        ...prevState,
        report: res
      }));
    }
  }

  render() {
    const { report } = this.state;
    const { coa } = this.props;

    return (
      <div className="big-book-report">
        <ReportCard
          coa={coa}
          onSearch={this._onSearch}
          onSearchCoa={this._onSearchCoa}
          coaRangeSelector
          paging={false}
          header={(
            <div className="big-book-report__title">
              <h1>Laporan Buku Besar</h1>
            </div>
          )}
        >
          {
          map(report, (list) => {
            const { initial_balance } = list;
            let finalBalance = initial_balance || 0;
            let totalDebit = 0;
            let totalCredit = 0;
            return (
              <div className="big-book-report__content" key={`big-book-report-content__${list.code}`}>
                <div className="big-book-report__header">
                  <div className="big-book-report__info">
                    <p>No. Akun</p>
                    <p>: {list.code} </p>
                  </div>
                  <div className="big-book-report__info">
                    <p>Nama Akun</p>
                    <p>: {list.title}</p>
                  </div>
                  <div className="big-book-report__info">
                    <p>Akun D/K</p>
                    <p>: {list.type}</p>
                  </div>
                </div>
                <table className="big-book-report__table">
                  <thead>
                    <tr>
                      <th>Tanggal</th>
                      <th>Nomor Jurnal</th>
                      <th>Uraian</th>
                      <th className="nominal" width="25%">Saldo Awal</th>
                      <th className="nominal" width="25%">Debit</th>
                      <th className="nominal" width="25%">Kredit</th>
                      <th className="nominal">Saldo Akhir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      map(list.journals, (journal, journalIndex) => {
                        const initialBalance = parseFloat(finalBalance) || 0;
                        const debit = parseFloat(journal.debit) || 0;
                        const credit = parseFloat(journal.credit) || 0;
                        finalBalance = initialBalance - debit + credit;
                        totalDebit = totalDebit + debit;
                        totalCredit = totalCredit + credit;
                        return (
                          <tr key={`big-book-report-content__${list.code}__${journal.journal_number}__${journalIndex}`}>
                            <td>{dateFormat(journal.date, 'DD-MM-YYYY')}</td>
                            <td>{journal.journal_number}</td>
                            <td>{journal.description}</td>
                            <td className="nominal">{commaFormatted(initialBalance)}</td>
                            <td className="nominal">{commaFormatted(debit)}</td>
                            <td className="nominal">{commaFormatted(credit)}</td>
                            <td className="nominal">{commaFormatted(finalBalance)}</td>
                          </tr>
                        );
                      })
                    }
                    <tr className="total">
                      <td colSpan={4} />
                      <td className="nominal">{commaFormatted(totalDebit)}</td>
                      <td className="nominal">{commaFormatted(totalCredit)}</td>
                      <td className="nominal">{commaFormatted(finalBalance)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })
        }
        </ReportCard>
      </div>
    );
  }
}
BigBookReport.propTypes = {
  handleGetCoa: PropTypes.func,
  handleGetGeneralLedgerReport: PropTypes.func,
  coa: PropTypes.object,
};
BigBookReport.defaultProps = {
  handleGetCoa: noop,
  handleGetGeneralLedgerReport: noop,
  coa: null,
};
