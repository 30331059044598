import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty } from "lodash";
import { Button, Input } from "../../../../../components/base";
import BudgetField from "./BudgetField.component";
import language from "../../../../../languages";
import {
  calculateAllSemester,
  commaFormatted,
} from "../../../../../utils/transformer.util";

export default class BudgetForm extends Component {
  constructor(props) {
    super(props);
    this.onAddSemesterOne = this.onAddSemesterOne.bind(this);
    this.onAddSemesterTwo = this.onAddSemesterTwo.bind(this);
    this.onDeleteSemesterOne = this.onDeleteSemesterOne.bind(this);
    this.onDeleteSemesterTwo = this.onDeleteSemesterTwo.bind(this);
    this.onChangeSemesterOne = this.onChangeSemesterOne.bind(this);
    this.onChangeSemesterTwo = this.onChangeSemesterTwo.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
  }

  onAddSemesterOne() {
    const { onAddSemester } = this.props;
    onAddSemester("semesterOne");
  }

  onAddSemesterTwo() {
    const { onAddSemester } = this.props;
    onAddSemester("semesterTwo");
  }

  onDeleteSemesterOne(idx) {
    const { onDeleteSemester } = this.props;
    onDeleteSemester("semesterOne", idx);
  }

  onDeleteSemesterTwo(idx) {
    const { onDeleteSemester } = this.props;
    onDeleteSemester("semesterTwo", idx);
  }

  onChangeSemesterOne(event) {
    const { onChange } = this.props;
    onChange(event, "semesterOne");
  }

  onChangeSemesterTwo(event) {
    const { onChange } = this.props;
    onChange(event, "semesterTwo");
  }

  onRemoveFile(index, semester) {
    const { onRemoveFile } = this.props;
    onRemoveFile(index, semester);
  }

  render() {
    const {
      semesterOne,
      semesterOneError,
      semesterTwo,
      semesterTwoError,
      coa,
      type,
      status,
      handleEditBudgetDetail,
      code,
      onUpdateForm,
      handleDeleteBudgetDetail,
      onUpdateError,
      onSearchCoa,
      user,
      recommendations,
      workflow,
      handleFileUpload,
      onRemoveFile,
      handleFileDownload,
    } = this.props;
    const { user_group } = user;
    let canAdd = user_group.name === "Keuangan Sekolah" ? true : false;
    let countSummary = calculateAllSemester(semesterOne, semesterTwo);

    if (
      canAdd &&
      workflow.next_role &&
      workflow.next_role !== user_group.name
    ) {
      canAdd = false;
    }

    let tableColSpan = 13;

    return (
      <div className="manage-budget__table-wrapper">
        <table className="manage-budget__form-table table">
          <thead>
            <tr>
              <th className="manage-budget__row" rowSpan={2}>
                Kode Akun
              </th>
              <th>Nama Akun</th>
              <th>Keterangan</th>
              <th key="th_row_arr_1">Kuantitas</th>
              <th key="th_row_arr_2">Satuan</th>
              <th key="th_row_arr_3">Periode</th>
              <th>Yayasan</th>
              <th>Komite</th>
              <th>Intern</th>
              <th>BOS</th>
              <th>Jumlah</th>
              <th>File</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h3>Semester II Tahun Ajaran Berjalan</h3>
                  <div>
                    <Button
                      onClick={this.onAddSemesterTwo}
                      title={
                        language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE
                      }
                    />
                  </div>
                </div>
              </td>
            </tr>
            {map(semesterTwo, (data, idx) => (
              <BudgetField
                key={`budget_field_${idx}`}
                status={status}
                code={code}
                type={type}
                coa={coa}
                data={data}
                isNew={isEmpty(data.unique_id)}
                error={semesterTwoError[idx]}
                idx={idx}
                handleEditBudgetDetail={handleEditBudgetDetail}
                handleDeleteBudgetDetail={handleDeleteBudgetDetail}
                handleFileUpload={handleFileUpload}
                handleFileDownload={handleFileDownload}
                onUpdateForm={onUpdateForm}
                onUpdateError={onUpdateError}
                onChange={this.onChangeSemesterTwo}
                onDeleteSemester={() => this.onDeleteSemesterTwo(idx)}
                onSearchCoa={onSearchCoa}
                onRemoveFile={(index) => this.onRemoveFile(idx, "semesterTwo")}
                user={user}
                recommendations={recommendations[data.id]}
                workflow={workflow}
              />
            ))}
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h3>Semester I Tahun Ajaran Berikutnya</h3>
                  <div>
                    <Button
                      onClick={this.onAddSemesterOne}
                      title={
                        language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE
                      }
                    />
                  </div>
                </div>
              </td>
            </tr>
            {map(semesterOne, (data, idx) => (
              <BudgetField
                key={`budget_field_${idx}`}
                status={status}
                code={code}
                type={type}
                coa={coa}
                idx={idx}
                data={data}
                isNew={isEmpty(data.unique_id)}
                error={semesterOneError[idx]}
                handleEditBudgetDetail={handleEditBudgetDetail}
                handleDeleteBudgetDetail={handleDeleteBudgetDetail}
                handleFileUpload={handleFileUpload}
                onUpdateForm={onUpdateForm}
                onUpdateError={onUpdateError}
                onChange={this.onChangeSemesterOne}
                onDeleteSemester={() => this.onDeleteSemesterOne(idx)}
                onSearchCoa={onSearchCoa}
                onRemoveFile={(index) => this.onRemoveFile(idx, "semesterOne")}
                user={user}
                recommendations={recommendations[data.id]}
                workflow={workflow}
              />
            ))}
            <tr className="manage-budget__summary">
              <td colSpan={6}>
                {type === "INCOME" ? "Total Pendapatan" : "Total Pengeluaran"}
              </td>
              <td className="nominal">{countSummary.ypl}</td>
              <td className="nominal">{countSummary.committee}</td>
              <td className="nominal">{countSummary.intern}</td>
              <td className="nominal">{countSummary.bos}</td>
              <td className="nominal">{countSummary.total}</td>
              <td colSpan="2"></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
BudgetForm.propTypes = {
  semesterOne: PropTypes.array.isRequired,
  semesterTwo: PropTypes.array.isRequired,
  inventaris: PropTypes.array,
  semesterOneError: PropTypes.array.isRequired,
  semesterTwoError: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onAddSemester: PropTypes.func,
  onDeleteSemester: PropTypes.func,
  handleEditBudgetDetail: PropTypes.func,
  handleDeleteBudgetDetail: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFileDownload: PropTypes.func,
  onUpdateForm: PropTypes.func,
  onUpdateError: PropTypes.func,
  onRemoveFile: PropTypes.func,
  code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  coa: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onSearchCoa: PropTypes.func,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  workflow: PropTypes.object,
};
BudgetForm.defaultProps = {
  inventaris: null,
  onAddSemester: noop,
  onDeleteSemester: noop,
  handleEditBudgetDetail: noop,
  handleDeleteBudgetDetail: noop,
  handleFileUpload: noop,
  handleFileDownload: noop,
  onUpdateForm: noop,
  onUpdateError: noop,
  onChange: noop,
  onSearchCoa: noop,
  onRemoveFile: noop,
  user: null,
  recommendations: {},
  workflow: null,
};
