import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { commaFormatted, dateFormat, normalizeAmount } from '../../../utils/transformer.util';
import DatePicker from 'react-date-picker';

import { GENDER } from '../../../variables/common.variable';

export default class Input extends PureComponent {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
    this.setValue = this.setValue.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onDatePickerChange = this.onDatePickerChange.bind(this);
    this.state = {
      value: '',
      formattedValue: '',
    };
  }

  componentDidMount() {
    const { value } = this.props;

    this.setValue(value);
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps;
    const { value } = this.props;

    if(value !== prevValue) {
    //  this.setValue(value);
    }
  }

  setValue(value) {
    const { isNumber, onChange } = this.props;
    const formattedValue = isNumber ? commaFormatted(value) : value;

    this.setState({
      value,
      formattedValue
    });
  }

  onDatePickerChange(value) {
    const { name, type } = this.props;
    this.onChange(
      {
        target: {
          value: (value && value !== '') ? dateFormat(value, 'YYYY-MM-DD') : '',
          name,
          type,
          dataset: {},
        }
      }
    );
  }

  onChange(event) {
    const { isNumber, onChange, type } = this.props;
    const { target = {} } = event;
    const { value } = target;
    const formattedValue = value;

    this.setState({
      value: (isNumber) ? normalizeAmount(value) : value,
      formattedValue
    });

    onChange(event);
  }

  onBlur(event) {
    const { onBlur } = this.props;
    const { value } = this.state;

    onBlur(event, value);
  }

  onFocus(event) {
    const { isNumber } = this.props;
    const { formattedValue } = this.state;

    if(isNumber) {
        if(formattedValue === 0 || formattedValue === '-') {
          this.setState({
            value: '',
            formattedValue: ''
          });
        }
    }
  }

  setRef(input) {
    this.input = input;
  }

  render() {
    const {
      theme,
      type,
      label,
      placeholder,
      value,
      onChange,
      onBlur,
      error,
      classNames,
      leftIcon,
      rightIcon,
      disabled,
      noMargin,
      isNumber,
      edit,
      ...others
    } = this.props;
    const wrapperClassnames = classnames([
      'input',
      !edit && 'input__preview',
      noMargin && 'input__no-margin',
    ]);
    const labelClassnames = classnames([
      'input__label',
      error && 'input__label--error',
    ]);
    const containerClassnames = classnames([
      'input__container',
      disabled && 'input__disabled',
      error && 'input__container--error',
    ]);
    const inputClassnames = classnames([
      `input__text input__text__${theme}`,
      leftIcon && `input__text__${theme}--icon-left`,
      rightIcon && `input__text__${theme}--icon-right`,
      (theme === 'default' && leftIcon && rightIcon) && 'input__text--no-border',
      disabled && 'input__text--disabled',
      classNames,
    ]);

    const formattedValue = isNumber ?
      commaFormatted(value) : (type === 'date') ?
        (value && value !== '') ? new Date(value) : new Date()
        : value;

    let field;
    if(type === 'date') {
      field = (
          <DatePicker
            onChange={this.onDatePickerChange}
            value={formattedValue}
            format='dd-MM-y'
            className="input__datepicker input__datepicker__default"
            disabled={disabled}
          />
      )
    } else {
      field = (
          <input
            ref={this.setRef}
            type={type}
            placeholder={placeholder}
            className={inputClassnames}
            value={formattedValue}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            autoComplete="new-password"
            {...others}
          />
      );
    }

    if(!edit) {
      let displayValue = formattedValue;

      if(type === 'date') {
        displayValue =  dateFormat(displayValue, 'DD-MM-YYYY');
      }
      field = (<p className="input__preview">{displayValue}</p>)
    }
    return (
      <div className={wrapperClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        <div className={containerClassnames}>
          {field}
          {
            leftIcon
            && (
              <span className={`input__icon-left input__icon-left__${theme}`}>
                {
                typeof leftIcon === 'string'
                  ? <i className={leftIcon} />
                  : leftIcon
              }
              </span>
            )
          }
          {
            rightIcon
            && (
              <span className={`input__icon-right input__icon-right__${theme}`}>
                {
                typeof rightIcon === 'string'
                  ? <i className={rightIcon} />
                  : rightIcon
              }
              </span>
            )
          }
        </div>
        {error && <p className="input__err">{error}</p>}
      </div>
    );
  }
}
Input.propTypes = {
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  isNumber: PropTypes.bool,
  edit: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
Input.defaultProps = {
  disabled: false,
  noMargin: false,
  isNumber: false,
  edit: true,
  theme: 'default',
  type: 'text',
  label: '',
  placeholder: '',
  error: '',
  classNames: '',
  leftIcon: '',
  rightIcon: '',
  value: '',
  onChange: noop,
  onBlur: noop,
};
