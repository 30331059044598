import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty,noop } from 'lodash';
import DataKeluarga from './components/DataKeluargaForm.component';
import DataPendidikanForm from './components/DataPendidikanForm.component';
import DataSuratPeringatan from './components/DataSuratPeringatan.component';
import DataPendidikanNonFormal from './components/DataPendidikanNonFormal.component';
import DataRiwayatPekerjaan from './components/DataRiwayatPekerjaan.component';
import DataCutiLama from './components/DataCutiLamaForm.component';
import { commaFormatted, manageStaffFormState } from '../../utils/transformer.util';
import Button from '../../components/base/Button/Button.component';
import {GENDER} from '../../variables/common.variable';
import {Input, Select} from '../../components/base';

export default class ViewStaff extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onDeleteListKeluarga = this._onDeleteListKeluarga.bind(this);
    this._onDeleteListSuratPeringatan = this._onDeleteListSuratPeringatan.bind(this);
    this._onDeleteListDataPendidikanNonFormal = this._onDeleteListDataPendidikanNonFormal.bind(this);
    this._onDeleteListRiwayatPekerjaan = this._onDeleteListRiwayatPekerjaan.bind(this);
    this._onDeleteListCutiLama = this._onDeleteListCutiLama.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);

    this._setForm = this._setForm.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);

    this._onClickAddDataCutiLama = this._onClickAddDataCutiLama.bind(this); 
    this._onClickAddDataRiwayatPekerjaan = this._onClickAddDataRiwayatPekerjaan.bind(this); 
    this._onClickAddDataPendidikanNonFormal = this._onClickAddDataPendidikanNonFormal.bind(this); 
    this._onClickAddDataKeluarga = this._onClickAddDataKeluarga.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickAddDataSuratPeringatan = this._onClickAddDataSuratPeringatan.bind(this);

    this.state = {
      form: {
        value: {
          nip:'',
          nama_lengkap:'',
          nama_panggilan:'',
          email:'',
          tempat_lahir:'',
          tanggal_lahir:'',
          id_absensi_kartu_fingerprint:'',
          jenis_kelamin:'',
          tinggi:'',
          berat:'',
          alamat_tempat_tinggal:'',
          rt:'',
          rw:'',
          kelurahan:'',
          kecamatan:'',
          kotamadya_kabupaten:'',
          propinsi:'',
          no_telpon:'',
          no_wa:'',
          agama:'',
          kewarganegaraan:'',
          golongan_darah:'',
          pendidikan_terakhir:'',
          status_perkawinan:'',
          status_kepegawaian:'',
          bahasa_yg_dikuasai:'',
          penyakit_yg_diderita:'',
          kelainan_fisik:'',
          no_ktp:'',
          no_kk:'',
          no_npwp:'',
          bank:'',
          no_rek_bank:'',
          bpjs_kesehatan_status:'',
          bpjs_kesehatan_no:'',
          bpjs_ketenagakerjaan_status:'',
          bpjs_ketenagakerjaan_no:'',
          tanggal_mulai_bekerja:'',
          masa_kerja_saat_ini:'',
          tgl_keluar_pensiun:'',
          keterangan_keluar_pensiun:'',
          unitkerja:'',
          jabatan:'',
          golongan:'',
          no_yadapen:'',
          jabatan_tugas_tambahan:'',
          aktif_non_aktif:'',
          apd:'',
          region:'',
          pendidikan:'',
          tertanggung:'',
          cuti_panjang:'',
          memorandum: '',
          pendidikan_formal: '',
          pendidikan_non_formal: '',
          pekerjaan_history:'',
          keluarga: '',
        },
        error: {
          agama:'',
          nip:'',
          nama_lengkap:'',
          nama_panggilan:'',
          email:'',
          tempat_lahir:'',
          tanggal_lahir:'',
          id_absensi_kartu_fingerprint:'',
          jenis_kelamin:'',
          tinggi:'',
          berat:'',
          alamat_tempat_tinggal:'',
          rt:'',
          rw:'',
          kelurahan:'',
          kecamatan:'',
          kotamadya_kabupaten:'',
          propinsi:'',
          no_telpon:'',
          no_wa:'',
          kewarganegaraan:'',
          golongan_darah:'',
          pendidikan_terakhir:'',
          status_perkawinan:'',
          status_kepegawaian:'',
          bahasa_yg_dikuasai:'',
          penyakit_yg_diderita:'',
          kelainan_fisik:'',
          no_ktp:'',
          no_kk:'',
          no_npwp:'',
          bank:'',
          no_rek_bank:'',
          bpjs_kesehatan_status:'',
          bpjs_kesehatan_no:'',
          bpjs_ketenagakerjaan_status:'',
          bpjs_ketenagakerjaan_no:'',
          tanggal_mulai_bekerja:'',
          masa_kerja_saat_ini:'',
          tgl_keluar_pensiun:'',
          keterangan_keluar_pensiun:'',
          unitkerja:'',
          jabatan:'',
          golongan:'',
          no_yadapen:'',
          jabatan_tugas_tambahan:'',
          aktif_non_aktif:'',
          apd:'',
          region:'',
          pendidikan:'',
          tertanggung:'',
          cuti_panjang:'',
          memorandum: '',
          pendidikan_formal: '',
          pendidikan_non_formal:'',
          cuti_panjang: '',
          pekerjaan_history: '',
          keluarga: '',
        },
      },
    };
    // console.log("ce state status pendidikan_formal")
    // console.log(this.state.form.value.keluarga
  }

  handle
  componentDidMount() {
    const { 
            location,handleGetAgamaOptions,handleGetGolonganDarah, handleGetJenjangPendidikanOptions,
            handleGetRegionOptions, handleGetStaffJabatanOptions, handleGetAktifNonAktifOptions, 
            handleGetPangkatGolonganOptions, handleGetPTKPOptions, handleGetStatusKepegawaianOptions, 
            handleGetUnitKerjaOptions } = this.props;
            // Ini ga da payloadnya, makanya ga tampil pan
            // harusnya ada payload di dalamnya
            handleGetAgamaOptions();
            // console.log(typeof location.state.data.unit_kerja)
            if(typeof location.state.data.unit_kerja !== 'undefined')
            {
              handleGetStaffJabatanOptions({ unitkerja: location.state.data.unit_kerja.id_unit_kerja });
            }else{
              handleGetStaffJabatanOptions();
            }
            handleGetPTKPOptions();
            handleGetJenjangPendidikanOptions();
            handleGetRegionOptions();
            
            if(typeof location.state.data.unit_kerja != 'undefined')
            {
              handleGetPangkatGolonganOptions({ unitkerja: location.state.data.unit_kerja.id_unit_kerja });
            }else{
              handleGetPangkatGolonganOptions();
            }
            handleGetStatusKepegawaianOptions();
            handleGetAktifNonAktifOptions();
            handleGetUnitKerjaOptions();
            handleGetGolonganDarah();
  
    // const response = await

    //event target ga akan bisa masuk ke componentDidMount
    // const { name, value, dataset, checked, type,} = event.target;
    const { isEdit = false, data } = location.state;
    if (isEdit) {
      this._setForm(data.nip);
    }

    //contoh:
    // handleGetAgamaOptions({ unit_kerja: data.unit_kerja });
        // console.log(handleGetPangkatGolonganOptions)
  }
  
    // console.log(handleGetStatusKepegawaianOptions)
  
  _onClickAdd(data) {
    const { form } = this.state;  
    const { pendidikan_formal , pendidikan_formal_name  } = form.value;
    //kalau bentuk array ngga boleh diubah langsung begini, bisa efek ke array yang lain, harus di clone dulu
    pendidikan_formal.push({
      id_staff_edu_formal:'',
      pendidikan_formal_name:'',
      level:'',
      nama_sekolah:'',
      kota:'',
      jurusan:'',
      dari_tahun:'',
      sampai_tahun:'',
    });
    // console.log("cek add pendidikan_formal")
    // console.log(pendidikan_formal)

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          pendidikan_formal,
          pendidikan_formal_name,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }
  
  _onClickAddDataSuratPeringatan(data) {
    const { form } = this.state;  
    const { memorandum , memorandum_name } = form.value;
    memorandum.push({
      memorandum_name:'',
      level:'',
      tanggal:'',
      no_surat:'',
      jenis_pelanggaran:'',
      keterangan:'',
    });
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          memorandum,
          memorandum_name,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }
  
  _onClickAddDataRiwayatPekerjaan(data) {
    const { form } = this.state;  
    const { pekerjaan_history , pekerjaan_history_name } = form.value;
    pekerjaan_history.push({
      pekerjaan_history_name:'',
      perusahaan:'',
      jabatan:'',
      tahun:'',
      sampai_tahun:'',
      keterangan:'',
    });
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          pekerjaan_history,
          pekerjaan_history_name,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onClickAddDataPendidikanNonFormal(data) {
    const { form } = this.state;  
    const { pendidikan_non_formal , pendidikan_non_formal_name } = form.value;
    pendidikan_non_formal.push({
      data:'',
      nama_training:'',
      lembaga:'',
      kota:'',
      tahun:'',
      lama:'',
    });
    
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          pendidikan_non_formal,
          pendidikan_non_formal_name,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onClickAddDataCutiLama(data) {
    const { form } = this.state;  
    const { cuti_panjang , cuti_panjang_name } = form.value;
    cuti_panjang.push({
      data:'',
      alasan_cuti:'',
      tanggal:'',
      sampai_tanggal:'',
      keterangan:'',
    });
    
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          cuti_panjang,
          cuti_panjang_name,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onClickAddDataKeluarga(data) {
    const { form } = this.state;
    const {nip, keluarga ,keluarga_name , keluarga_name_pendidikan , 
          keluarga_name_agama, keluarga_name_tertanggung
          } = form.value;
    keluarga.push({
      nip,
      keluarga_name:'',
      keluarga_name_pendidikan:'',
      keluarga_name_agama:'',
      keluarga_name_tertanggung:'',
      nama_lengkap:'',
      tanggal_lahir:'',
      agama:'',
      pendidikan:'',
      level:'',
      status:'',
      tertanggung:'',
    });
    // console.log("cek add keluarga")
    // console.log(keluarga)

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          keluarga,
          keluarga_name,
          keluarga_name_agama,
          keluarga_name_pendidikan,
          keluarga_name_tertanggung,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { pendidikan_formal } = prevState.form.value;
      const list = prevState.form.value.pendidikan_formal;

      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          pendidikan_formal: list,
        }
      };
    });
  }

  _onDeleteListSuratPeringatan(idx) {
    this.setState((prevState) => {
      const { memorandum } = prevState.form.value;
      const list = prevState.form.value.memorandum;

      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          memorandum: list,
        }
      };
    });
  }

  _onDeleteListCutiLama(idx) {
    this.setState((prevState) => {
      const { cuti_panjang } = prevState.form.value;
      const list = prevState.form.value.cuti_panjang;
      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          cuti_panjang: list,
        }
      };
    });
  }

  _onDeleteListRiwayatPekerjaan(idx) {
    this.setState((prevState) => {
      const { pekerjaan_history } = prevState.form.value;
      const list = prevState.form.value.pekerjaan_history;
      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          pekerjaan_history: list,
        }
      };
    });
  }

  _onDeleteListDataPendidikanNonFormal(idx) {
    this.setState((prevState) => {
      const { pendidikan_non_formal } = prevState.form.value;
      const list = prevState.form.value.pendidikan_non_formal;
      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          pendidikan_non_formal: list,
        }
      };
    });
  }

  _onDeleteListKeluarga(idx) {
    this.setState((prevState) => {
      const { keluarga } = prevState.form.value;
      const list = prevState.form.value.keluarga;
      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          keluarga: list,
        }
      };
    });
  }

  async _setForm(nip) {
    const { handleGetStaffById } = this.props;
    try {
      const payload = await handleGetStaffById({ nip });
      // console.log("paload bor manageStaffFormState")
      // console.log(payload)
      this.setState(manageStaffFormState(payload));
    } catch (err) {
      // err action
    }
  }

  async _onFormChange(event) {
    const { handleGetStaffJabatanOptions , handleGetPangkatGolonganOptions} = this.props;
    const { name, value, dataset, checked, type,} = event.target;
    const { inputType = 'text', inputArray = false, 
            arrayPosition = 0, fieldName,
          } = dataset;
    if(name === 'unitkerja') {
      await handleGetStaffJabatanOptions ({
        unitkerja: value
    });
      await handleGetPangkatGolonganOptions ({
        unitkerja: value
      });
    } 
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;
      if (inputType === 'number') {
        // formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
         
      };
    });
    // console.log("cek consolo agamaa");
    // console.log(name);
  }

  _onAddList(fieldName) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {},
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    // console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        //formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        newPlafon = prevState.form.value.pendidikan_formal_name;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ?
              { pendidikan_formal_name: newPlafon } :
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { handleManageStaff, history, location,
          } = this.props;
    const { data, isEdit = false } = location.state;
    // console.log(data)
    // console.log("cek data")
    handleManageStaff({ nip: data.nip, ...form.value }, history.goBack, isEdit);
    // console.log(handleManageStaff)
    // console.log("paload bor")
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/personalia/kelola-staff/edit', { data: val, isEdit: true });
  }

  deleteDataPendidikan(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({people: array});
    }
  }

  render() {
    const { form } = this.state;
    const { value } = form;
    // console.log(value)
    const { agama={}, unitkerja={},aktifnonaktif={}, 
            jenjangpendidikan={}, ptkp={}, jabatan={},golongan={}, statuskepegawaian={}, 
            golongandarah={}
          } = this.props;
    return (
      
      <div className="manage-staff-form__custom-form">
          {/* <div className="manage-staff">
            <Button
              onClick={this._onClickEdit}
              title="Edit"
            />
          </div> */}
         <h1>Identitas Pegawai</h1>
       
      <div className="manage-staff-form__custom-form-row">
        <div className="manage-staff-form__custom-form-row__field"></div>
          <Input 
              isRequired
              name="nip"
              value={value.nip}
              label="No G"
              onChange={this._onFormChange}
              readonly="readonly"
            />
          <div className="manage-staff-form__custom-form-row__field"></div>
            <Input 
              isRequired
              name="nama_lengkap"
              value={value.nama_lengkap}
              label="Nama Lengkap Pegawai"
              onChange={this._onFormChange}
              readonly="readonly"
            />
      </div>

      <div className="manage-staff-form__custom-form-row">
          <Input 
              isRequired
              name="nama_panggilan"
              value={value.nama_panggilan}
              label="Nama Panggilan"
              onChange={this._onFormChange}
              readonly="readonly"
            />

          <div className="manage-staff-form__custom-form-row__field"> </div>
             <Select 
             isRequired
              name="jenis_kelamin"
              label="Jenis Kelamin"
              data={GENDER}
              value={value.jenis_kelamin}
              onChange={this._onFormChange}
              placeholder="Pilih Jenis Kelamin"
              disabled
             />
      </div>

    <div className="manage-staff-form__custom-form-row__field">
      <div className="list-form__content list-form__date-of-birth">
         <Input 
              isRequired
              type="dualInput"
               name="tempat_lahir"
               value={value.tempat_lahir}
               label="Tempat Lahir"
               onChange={this._onFormChange}
               readonly="readonly"
        />
        <Input 
          isRequired
          name="tanggal_lahir"
          value={value.tanggal_lahir}
          label="Tanggal Lahir"
          onChange={this._onFormChange}
          readonly="readonly"
          />
      </div>
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
            isRequired
            name="id_absensi_kartu_fingerprint"
            value={value.id_absensi_kartu_fingerprint}
            label="ID Absensi / Fingerprint / Kartu"
            onChange={this._onFormChange}
            readonly="readonly"
          />
      </div>
    <div className="manage-staff-form__custom-form-row__field">
      <div className="list-form__content list-form__date-of-birth">
        <Input 
              isRequired
               name="rt"
               value={value.rt}
               label="RT"
               onChange={this._onFormChange}
               readonly="readonly"
        />
        <Input 
                isRequired
                type="rw"
                name="rw"
                value={value.rw}
                label="RW"
                onChange={this._onFormChange}
                readonly="readonly"
        />
        </div>
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
            isRequired
            name="email"
            value={value.email}
            label="Email"
            onChange={this._onFormChange}
            readonly="readonly"
          />
      </div>
    <div className="manage-staff-form__custom-form-row__field">
      <div className="list-form__content list-form__date-of-birth">
        <Input 
            isRequired
            name="tinggi"
            value={value.tinggi}
            label="Tinggi"
            onChange={this._onFormChange}
            readonly="readonly"
        />
        <Input 
          isRequired
          type="berat"
          name="berat"
          value={value.berat}
          label="Berat"
          onChange={this._onFormChange}
          readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
            isRequired
            name="kecamatan"
            value={value.kecamatan}
            label="Kecamatan"
            onChange={this._onFormChange}
            readonly="readonly"
        />
    </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="alamat_tempat_tinggal"
              value={value.alamat_tempat_tinggal}
              label="Alamat"
              onChange={this._onFormChange}
              readonly="readonly"
        />
       <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="propinsi"
              value={value.propinsi}
              label="Provinsi"
              onChange={this._onFormChange}
              readonly="readonly"
        />
    </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="kelurahan"
              value={value.kelurahan}
              label="Kelurahan"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="kotamadya_kabupaten"
              value={value.kotamadya_kabupaten}
              label="Kotamadya / Kabupaten"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="no_telpon"
              value={value.no_telpon}
              label="No Telpon"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="no_wa"
              value={value.no_wa}
              label="No WA"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
	      <Select 
              name="agama"
              value={value.agama} 
              placeholder="Pilih Agama"
              data={agama.list}
              label="Agama"
              onChange={this._onFormChange}
              disabled
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              name="kewarganegaraan"
              value={value.kewarganegaraan}
              label="Kewarganegaraan"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
	      <Select 
              name="golongan_darah"
              value={value.golongan_darah || ""}
              placeholder="Pilih Golongan Darah"
              data={golongandarah.list}
              label="Golongan Darah"
              onChange={this._onFormChange}
              disabled
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="pendidikan_terakhir"
              value={value.pendidikan_terakhir}
              label="Pendidikan Terakhir"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="bahasa_yg_dikuasai"
              value={value.bahasa_yg_dikuasai}
              label="Bahasa Yang dikuasai"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="penyakit_yg_diderita"
              value={value.penyakit_yg_diderita}
              label="Penyakit Yang Diderita"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="kelainan_fisik"
              value={value.kelainan_fisik}
              label="Kelainan Fisik"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Select 
              name="status_kepegawaian"
              value={value.status_kepegawaian}
              placeholder="Pilih Status Kepegawaian"
              data={statuskepegawaian.list}
              label="Status Kepegawaian"
              onChange={this._onFormChange}
              onFocus="this.defaultIndex=this.selectedIndex;"
              onChange="this.selectedIndex=this.defaultIndex;"
              disabled
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="jabatan_tugas_tambahan"
              value={value.jabatan_tugas_tambahan}
              label="Jabatan Tugas Tambahan"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
    </div>
        <h1> Data Kepegawaian</h1>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="no_ktp"
              value={value.no_ktp}
              label="NIK KTP"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="no_kk"
              value={value.no_kk}
              label="NIK KK"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row__field">
      <div className="list-form__content list-form__date-of-birth">
        <Input 
              isRequired
              type="dualInput"
              name="bank"
              value={value.bank}
              label="Bank"
              onChange={this._onFormChange}
              readonly="readonly"
        />
        <Input 
              isRequired
              type="dualInput"
              name="no_rek_bank"
              value={value.no_rek_bank}
              label="No Rekening Bank"
              onChange={this._onFormChange}
              readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
              isRequired
              name="no_npwp"
              value={value.no_npwp}
              label="NPWP"
              onChange={this._onFormChange}
              readonly="readonly"
        />
    </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Select 
                name="unitkerja"
                value={value.unitkerja}
                placeholder="Pilih Unit Kerja"
                label="Unit Kerja"
                data={unitkerja.list}
                onChange={this._onFormChange}
                disabled
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Select 
                name="jabatan"
                value={value.jabatan}
                placeholder="Pilih Jabatan"
                data={jabatan.list}
                label="Jabatan"
                onChange={this._onFormChange}
                disabled
        />
    </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
	      <Select 
               name="aktif_non_aktif"
               placeholder="Pilih Aktif/Non Aktif"
               value={value.aktif_non_aktif}
               data={aktifnonaktif.list}
               label="Aktif/Non Aktif"
               onChange={this._onFormChange}
               disabled
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
        {console.log(golongan.list)}
        <Select
              name="golongan"
              value={value.golongan}
              placeholder="Pilih Golongan Kepangkatan"
              label="Golongan Kepangkatan"
              data={golongan.list}
              onChange={this._onFormChange}  
              disabled
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
	      <Input 
               name="tanggal_mulai_bekerja"
               value={value.tanggal_mulai_bekerja}
               label="Tanggal Masuk"
               onChange={this._onFormChange}
               readonly="readonly"
        />
    <div className="manage-staff-form__custom-form-row__field"></div>
      <Input 
              name="masa_kerja_saat_ini"
              value={value.masa_kerja_saat_ini}
              label="Masa Kerja Saat ini"
              onChange={this._onFormChange}
              readonly="readonly"
        />
    </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
	     <Input 
              name="tgl_keluar_pensiun"
              value={value.tgl_keluar_pensiun}
              label="Tanggal Keluar / Pensiun"
              onChange={this._onFormChange}
              readonly="readonly"
      />
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Input 
               name="keterangan_keluar_pensiun"
               value={value.keterangan_keluar_pensiun}
               label="Keterangan Keluar / Pensiun"
               onChange={this._onFormChange}
               readonly="readonly"
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
	     <Input 
               name="no_yadapen"
               value={value.no_yadapen}
               label="No Yadapen"
               onChange={this._onFormChange}
               readonly="readonly"
        />
      <div className="manage-staff-form__custom-form-row__field"></div>
       <Select 
               name="apd"
               value={value.apd}
               placeholder="Pilih Jenjang Pendiikan "
               data={jenjangpendidikan.list}
               label="APD"
               onChange={this._onFormChange}
               disabled
        />
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
        <Select 
                name="status_perkawinan"
                value={value.status_perkawinan}
                placeholder="Pilih Status Perkawinan"
                data={ptkp.list}
                label="Status Perkawinan"
                onChange={this._onFormChange}
                disabled
          />
       <div className="manage-staff-form__custom-form-row"></div>
    </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
      <Input 
                name="bpjs_ketenagakerjaan_status"
                value={value.bpjs_ketenagakerjaan_status}
                label="BPJS Ketenagakerjaan"
                onChange={this._onFormChange}
                readonly="readonly"
      />
      <div className="manage-staff-form__custom-form-row__field"></div>
      <Input 
                name="bpjs_ketenagakerjaan_no"
                value={value.bpjs_ketenagakerjaan_no}
                label="No Peserta"
                onChange={this._onFormChange}
                readonly="readonly"
      />  
      </div>
    <div className="manage-staff-form__custom-form-row">
      <div className="manage-staff-form__custom-form-row__field"></div>
      <Input 
                name="bpjs_kesehatan_status"
                value={value.bpjs_kesehatan_status}
                label="BPJS Kesehatan"
                onChange={this._onFormChange} 
                readonly="readonly"
      />
      <div className="manage-staff-form__custom-form-row__field"></div>
      <Input 
                name="bpjs_kesehatan_no"
                value={value.bpjs_kesehatan_no}
                label="No Peserta"
                onChange={this._onFormChange}
                readonly="readonly"
      />  
      </div>
       <form onSubmit={this._onSubmit}>
          <div>
          <h1>Data Pendidikan Form</h1>
            <DataPendidikanForm 
              pendidikan_formal_name ={jenjangpendidikan.list}
              // onAddList={this._onClickAdd}
              list={value.pendidikan_formal}
              // onDeleteList={this._onDeleteList}
              // onChange={this._onFormChange}
            />
          <h1>Data Keluarga Form</h1>
          <DataKeluarga 
            keluarga_name_pendidikan ={jenjangpendidikan.list}
            keluarga_name_agama ={agama.list}
            keluarga_name_tertanggung ={ptkp.list}
            // onAddList={this._onClickAddDataKeluarga}
            list={value.keluarga}
            // onDeleteListKeluarga={this._onDeleteListKeluarga}
            // onChange={this._onFormChange}
          />
          <h1>Data Surat Peringatan</h1>
          <DataSuratPeringatan
          //  onAddList={this._onClickAddDataSuratPeringatan}
           list={value.memorandum}
          //  onDeleteListSuratPeringatan={this._onDeleteListSuratPeringatan}
          //  onChange={this._onFormChange}
          />
          <h1>Data Pendidikan Non Formal</h1>
          <DataPendidikanNonFormal
          //  onAddList={this._onClickAddDataPendidikanNonFormal}
           list={value.pendidikan_non_formal}
          //  onDeleteListDataPendidikanNonFormal={this._onDeleteListDataPendidikanNonFormal}
          //  onChange={this._onFormChange}
          />
        <h1>Data Riwayat Pekerjaan</h1>
          <DataRiwayatPekerjaan
          //  onAddList={this._onClickAddDataRiwayatPekerjaan}
           list={value.pekerjaan_history}
          //  onDeleteListRiwayatPekerjaan={this._onDeleteListRiwayatPekerjaan}
          //  onChange={this._onFormChange}
          />
        <h1>Cuti Panjang Lebih dari 7 Hari</h1>
          <DataCutiLama
          //  onAddList={this._onClickAddDataCutiLama}
           list={value.cuti_panjang}
          //  onDeleteListCutiLama={this._onDeleteListCutiLama}
          //  onChange={this._onFormChange}
          />
          </div>
          <div className="manage-staff">
            {/* <Button
              type="submit"
              title="Simpan"
            /> */}
          </div>
        </form>
      </div>
    );
  }
}
ViewStaff.propTypes = {
  handleGetAktifNonAktifOptions : PropTypes.func,
  handleGetStaffJabatanOptions : PropTypes.func,
  handleGetPTKPOptions: PropTypes.func,
  handleGetRegionOptions : PropTypes.func,
  handleGetAgamaOptions: PropTypes.func,
  handleGetJenjangPendidikanOptions: PropTypes.func,
  handleGetStatusKepegawaianOptions: PropTypes.func,
  handleGetGolonganDarah : PropTypes.func,
  handleManageStaff: PropTypes.func,
  handleGetStaffById: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onClickEdit: PropTypes.object.isRequired,
  
};
ViewStaff.defaultProps = {
  handleGetAktifNonAktifOptions :noop,
  handleGetPTKPOptions: noop,
  handleGetJenjangPendidikanOptions: noop,
  handleGetRegionOptions :noop,
  handleGetStaffJabatanOptions :noop,
  handleGetStatusKepegawaianOptions: noop,
  handleGetAgamaOptions : noop,
  handleGetGolonganDarah : noop,
  handleManageStaff: noop,
  onClickEdit: noop,
  handleGetStaffById: noop,
};