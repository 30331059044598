import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { path, isLoggedIn } = rest;
  if (!isLoggedIn && path !== '/login') {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect to={{ pathname: '/login' }} />
        )}
      />
    );
  }
  if (isLoggedIn && path === '/login') {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect to={{ pathname: '/dashboard' }} />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )}
    />
  );
};

export default PrivateRoute;
