import React, { Component } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import {
  isEmpty, noop,
} from 'lodash';
import { Button, Textarea } from '../../../../components/base';
import ListForm from '../../../../components/ListForm/ListForm.component';
import BudgetRapbu from './components/BudgetRapbu.component';
import BudgetApbu from './components/BudgetApbu.component';
import language from '../../../../languages';
import { normalizeAmount, datetimeFormat } from '../../../../utils/transformer.util';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'

export default class ManageBudget extends Component {
  constructor(props) {
    super(props);
    this.onSetForm = this.onSetForm.bind(this);
    this.onChangeRecommendation = this.onChangeRecommendation.bind(this);
    this.onSaveRecommendations = this.onSaveRecommendations.bind(this);
    this.onSubmitApproval = this.onSubmitApproval.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onCheckAll = this.onCheckAll.bind(this);
    this.onSelectBudgetDetail = this.onSelectBudgetDetail.bind(this);
    this.setRemarks = this.setRemarks.bind(this);
    this.confirmReject = this.confirmReject.bind(this);
    this.hasRecommendations = this.hasRecommendations.bind(this);
    this.remarks = React.createRef();
    this.renderWorkflowHistory = this.renderWorkflowHistory.bind(this);
    this.calculateTotals = this.calculateTotals.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);

    const defaultRapbu = {
      estimasi_surplus_defisit: null,
      estimasi_ypl: null,
      estimasi_komite: null,
      estimasi_intern: null,
      estimasi_bos: null,
      saldo: null,
      saldo_ypl: null,
      saldo_komite: null,
      saldo_intern: null,
      saldo_bos: null,
      status_surplus_defisit: '',
      total_pendapatan: null,
      total_pendapatan_ypl: null,
      total_pendapatan_komite: null,
      total_pendapatan_intern: null,
      total_pendapatan_bos: null,
      total_pengeluaran: null,
      total_pengeluaran_ypl: null,
      total_pengeluaran_komite: null,
      total_pengeluaran_intern: null,
      total_pengeluaran_bos: null,
      total_inventaris: null,
      total_inventaris_ypl: null,
      total_inventaris_komite: null,
      total_inventaris_intern: null,
      total_inventaris_bos: null,
      total_beban: null,
      total_pendapatan_rekomendasi: null,
      total_pendapatan_rekomendasi_ypl: null,
      total_pendapatan_rekomendasi_komite: null,
      total_pendapatan_rekomendasi_intern: null,
      total_pendapatan_rekomendasi_bos: null,
      total_pengeluaran_rekomendasi: null,
      total_pengeluaran_rekomendasi_ypl: null,
      total_pengeluaran_rekomendasi_komite: null,
      total_pengeluaran_rekomendasi_intern: null,
      total_pengeluaran_rekomendasi_bos: null,
      total_inventaris_rekomendasi: null,
      total_inventaris_rekomendasi_ypl: null,
      total_inventaris_rekomendasi_komite: null,
      total_inventaris_rekomendasi_intern: null,
      total_inventaris_rekomendasi_bos: null,
      total_estimasi_rekomendasi: null,
      total_estimasi_rekomendasi_ypl: null,
      total_estimasi_rekomendasi_komite: null,
      total_estimasi_rekomendasi_intern: null,
      total_estimasi_rekomendasi_bos: null,
      total_saldo_rekomendasi: null,
      total_saldo_rekomendasi_ypl: null,
      total_saldo_rekomendasi_komite: null,
      total_saldo_rekomendasi_intern: null,
      total_saldo_rekomendasi_bos: null,


    }

    this.state = {
      budget: {},
      pendapatan_id: [],
      pengeluaran_id: [],
      inventaris_id: [],
      rapbuSummary: {
        semesterOne: { ...defaultRapbu },
        semesterTwo: { ...defaultRapbu },
        inventaris: [],
      },
      recommendations: {
        ypl: {},
        committee: {},
        intern: {},
        bos: {},
      },
      selectedBudgetDetails: [],
      workflow: [],
      remarks: '',
    };
  }

  componentDidMount() {
    this.onSetForm();
  }

  async onSetForm() {
    const { handleGetBudgetDetail, location } = this.props;
    const { account_unique_id, head_unique_id } = location.state;
    const res = await handleGetBudgetDetail({ account_unique_id, head_unique_id, type: 'RAPBU' });

    this.setState(prevState => ({
      budget: res.budget,
      pendapatan_id: res.pendapatan_id,
      pengeluaran_id: res.pengeluaran_id,
      inventaris_id: res.inventaris_id,
      rapbuSummary: {
        semesterOne: res.ganjil || [],
        semesterTwo: res.genap || [],
        inventaris: res.inventaris || [],
        ...res,
      },
      recommendations: res.recommendations || {...prevState.recommendations},
      workflow: res.workflow || [],
    }));
  }

  onChangeRecommendation(event) {
    const { user } = this.props;
    const { user_groups_id, user_group } = user;
    const userGroup = user_group.name;
    const { target } = event;
    const { dataset, value, name: pos } = target;
    const { referenceid, postype, rapbu } = dataset;
    const { recommendations, rapbuSummary } = this.state;
    const newRecommendations = {...recommendations};
    const { persentase } = rapbuSummary;
    const recommendation = newRecommendations[user_groups_id] || {};
    let fieldname = pos;

    if(fieldname === 'committee') {
      fieldname = 'komite';
    }

    if (!recommendation[pos]) {
      recommendation[pos] = {};
    }

    recommendation[pos][referenceid] = normalizeAmount(value);
    if(userGroup === 'Manager Keuangan') {
      if(!persentase || !persentase[pos]) {
        persentase[pos] = {};
      }
      persentase[pos][referenceid] = 0;
      if(rapbu > 0) {
        persentase[pos][referenceid] =
        (Math.round((recommendation[pos][referenceid]/rapbu) * 10000) / 100);
      }
    }

    if(userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') {
      rapbuSummary[`total_${postype}_rekomendasi_${fieldname}`] = 0;
    } else if (userGroup === 'Manager Keuangan') {
      rapbuSummary[`total_${postype}_apbu_${fieldname}`] = 0;
    }

    for(let ref in recommendation[pos]) {
      if(this.state[`${postype}_id`].includes(parseInt(ref))) {
        let recommendationValue = parseFloat(recommendation[pos][ref]);
        if( ref === referenceid ) {
          recommendationValue = parseFloat(normalizeAmount(value));
        }

        if (userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') {
          rapbuSummary[`total_${postype}_rekomendasi_${fieldname}`] += normalizeAmount(recommendationValue);

        } else if (userGroup === 'Manager Keuangan' || userGroup === 'Bendahara') {
          rapbuSummary[`total_${postype}_apbu_${fieldname}`] += normalizeAmount(recommendationValue);
        }
      }
    };

    newRecommendations[user_groups_id] = recommendation;

    this.setState(prevState => ({
      ...prevState,
      recommendations: {
        ...newRecommendations,
      },
    }), () => this.calculateTotals(dataset, pos, normalizeAmount(value)));
  }

  async onSubmitApproval() {
    const { recommendations, selectedBudgetDetails } = this.state;
    const { handleSubmitApproval, location, history } = this.props;
    const { head_unique_id, data } = location.state;

    const res = await handleSubmitApproval({
      head: head_unique_id,
      recommendations,
      selectedBudgetDetails,
    });

    history.push('/dashboard/keuangan/penganggaran/anggaran');
  }

  onSaveRecommendations() {
    const { recommendations } = this.state;
    const { handleSaveRecommendations, history } = this.props;

    handleSaveRecommendations({
      recommendations,
    }, history.goBack);
  }

  onReject(remarks) {
    const { handleReject, history, location } = this.props;
    const { recommendations } = this.state;
    const { head_unique_id } = location.state;

    handleReject({
      head: head_unique_id,
      recommendations,
      remarks,
    }, history.goBack);
  }

  onCheckAll(checkboxes, isChecked = false) {
    const { user } = this.props;
    const { user_groups_id, user_group } = user;
    const userGroup = user_group.name;
    const { rapbuSummary, recommendations } = this.state;
    const { persentase } = rapbuSummary;
    const { semesterOne, semesterTwo, inventaris } = rapbuSummary;
    let newRecommendations = { ...recommendations };
    let reset = true;

    for (let key in checkboxes) {
      const el = checkboxes[key];
      const { dataset } = el;
      const { pos, referenceid, sourcevalue, rapbu, postype } = dataset;
      let value = Number(sourcevalue);
      let fieldname = pos;
      if(fieldname === 'committee') {
        fieldname = 'komite';
      }

	    if (user_group.name === 'Manager Keuangan') {
        if(recommendations && recommendations[10] && recommendations[10][pos]) {
          value  = recommendations[10][pos][referenceid] || 0;
        }
		else if(recommendations && recommendations[14] && recommendations[14][pos]) {
          value  = recommendations[14][pos][referenceid] || 0;
        }
      }
      if( !persentase || !persentase[pos] ) {
        persentase[pos] = {};
      }
      if(rapbu > 0) {
      	if(value > 0) {
          persentase[pos][referenceid] =
          (Math.round(value/rapbu) * 10000) / 100;
      	} else {
      	  persentase[pos][referenceid] = 0;
      	}
      } else {
        persentase[pos][referenceid] = 0;
      }

      if(!newRecommendations[user_groups_id]) {
        newRecommendations[user_groups_id] = {};
      }
      if(!newRecommendations[user_groups_id][pos]) {
        newRecommendations[user_groups_id][pos] = {};
      }

      if (user_group.name === 'Manager Keuangan') {
        if(recommendations && recommendations[10] && recommendations[10][pos]) {
          value  = recommendations[10][pos][referenceid] || 0;
        }
		else if(recommendations && recommendations[14] && recommendations[14][pos]) {
          value  = recommendations[14][pos][referenceid] || 0;
        }
      }

      newRecommendations[user_groups_id][pos][referenceid] = (isChecked) ? normalizeAmount(value) : 0;

      if(reset) {
        if (userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') {
          rapbuSummary[`total_pendapatan_rekomendasi_${fieldname}`] = 0;
          rapbuSummary[`total_pengeluaran_rekomendasi_${fieldname}`] = 0;
          rapbuSummary[`total_inventaris_rekomendasi_${fieldname}`] = 0;
        } else if (userGroup === 'Manager Keuangan' || userGroup === 'Bendahara') {
          rapbuSummary[`total_pendapatan_apbu_${fieldname}`] = 0;
          rapbuSummary[`total_pengeluaran_apbu_${fieldname}`] = 0;
          rapbuSummary[`total_inventaris_apbu_${fieldname}`] = 0;
        }
        reset = false;
      }

      if (userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') {
        rapbuSummary[`total_${postype}_rekomendasi_${fieldname}`] += (isChecked) ? normalizeAmount(value) : 0;
      } else if (userGroup === 'Manager Keuangan' || userGroup === 'Bendahara') {
        rapbuSummary[`total_${postype}_apbu_${fieldname}`] += (isChecked) ? normalizeAmount(value) : 0;
      }

    };

    let fieldsnames = ['ypl', 'komite', 'intern', 'bos'];

    fieldsnames.forEach((fieldname) => {
      if(userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') {
        rapbuSummary['total_estimasi_rekomendasi'] = rapbuSummary['total_pendapatan_rekomendasi'] - rapbuSummary['total_pengeluaran_rekomendasi'];
        rapbuSummary[`total_estimasi_rekomendasi_${fieldname}`] = rapbuSummary[`total_pendapatan_rekomendasi_${fieldname}`] - rapbuSummary[`total_pengeluaran_rekomendasi_${fieldname}`];
        rapbuSummary['total_saldo_rekomendasi'] = rapbuSummary['total_pendapatan_rekomendasi'] - rapbuSummary['total_pengeluaran_rekomendasi'] - rapbuSummary['total_inventaris_rekomendasi'];
        rapbuSummary[`total_saldo_rekomendasi_${fieldname}`] = rapbuSummary[`total_pendapatan_rekomendasi_${fieldname}`] - rapbuSummary[`total_pengeluaran_rekomendasi_${fieldname}`] - rapbuSummary[`total_inventaris_rekomendasi_${fieldname}`];
      }
      if(userGroup === 'Manager Keuangan') {
        rapbuSummary['total_estimasi_apbu'] = rapbuSummary['total_pendapatan_apbu'] - rapbuSummary['total_pengeluaran_apbu'];
        rapbuSummary[`total_estimasi_apbu_${fieldname}`] = rapbuSummary[`total_pendapatan_apbu_${fieldname}`] - rapbuSummary[`total_pengeluaran_apbu_${fieldname}`];
        rapbuSummary['total_saldo_apbu'] = rapbuSummary['total_pendapatan_apbu'] - rapbuSummary['total_pengeluaran_apbu'] - rapbuSummary['total_inventaris_apbu'];
        rapbuSummary[`total_saldo_apbu_${fieldname}`] = rapbuSummary[`total_pendapatan_apbu_${fieldname}`] - rapbuSummary[`total_pengeluaran_apbu_${fieldname}`] - rapbuSummary[`total_inventaris_apbu_${fieldname}`];
      }
    })



    this.setState(prevState => ({
      ...prevState,
      recommendations: {
        ...newRecommendations,
      },
    }));
  }

  onSelectBudgetDetail(target, prevRecommendation = null) {
    const { value, checked, dataset } = target;
    const { pos, referenceid, sourcevalue, postype, rapbu } = dataset;
    const { user } = this.props;
    const { user_groups_id } = user;
    const { selectedBudgetDetails, recommendations, rapbuSummary } = this.state;
    const { persentase } = rapbuSummary;
    const newRecommendations = { ...recommendations };
    const index = selectedBudgetDetails.indexOf(value);
    let newArray = selectedBudgetDetails.slice(0, selectedBudgetDetails.length);

    if(!newRecommendations[user_groups_id]) {
      newRecommendations[user_groups_id] = {};
    }

    if(!newRecommendations[user_groups_id][pos]) {
      newRecommendations[user_groups_id][pos] = {};
    }

    if(!persentase || !persentase[pos]) {
      persentase[pos] = {};
    }
    persentase[pos][referenceid] = 0;

    if (index !== -1) {
      if (checked === false) {
          newArray.splice(index, 1);
          newRecommendations[user_groups_id][pos][referenceid] = 0;
      } else {
        if(prevRecommendation !== null) {
          newRecommendations[user_groups_id][pos][referenceid] = prevRecommendation;
        } else {
          newRecommendations[user_groups_id][pos][referenceid] = sourcevalue;
        }
      }
    } else {
      if (checked) {
          newArray.push(value);
          if(prevRecommendation !== null) {
            newRecommendations[user_groups_id][pos][referenceid] = prevRecommendation;
          } else {
            newRecommendations[user_groups_id][pos][referenceid] = sourcevalue;
          }

          if(sourcevalue > 0) {
            persentase[pos][referenceid] =
            (Math.round((newRecommendations[user_groups_id][pos][referenceid]/sourcevalue) * 10000) / 100);
          }
      } else {
        newRecommendations[user_groups_id][pos][referenceid] = 0;
      }
    }

    this.setState(prevState => ({
      ...prevState,
      selectedBudgetDetails: newArray,
      recommendations: {
        ...newRecommendations,
      },
    }), () => this.calculateTotals(dataset, pos, (checked) ? sourcevalue : 0));
  }

  calculateTotals(dataset, pos, value) {
    const { user } = this.props;
    const { user_groups_id, user_group } = user;
    const userGroup = user_group.name;
    const { referenceid, postype, rapbu } = dataset;
    const { recommendations, rapbuSummary } = this.state;
    const { persentase } = rapbuSummary;
    const recommendation = recommendations[user_groups_id] || {};
    let fieldname = pos;

    if(fieldname === 'committee') {
      fieldname = 'komite';
    }

    if(userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') {
      rapbuSummary[`total_${postype}_rekomendasi_${fieldname}`] = 0;
    } else if (userGroup === 'Manager Keuangan') {
      rapbuSummary[`total_${postype}_apbu_${fieldname}`] = 0;
    }

    if(recommendation[pos]) {
      for(let ref in recommendation[pos]) {
        if(this.state[`${postype}_id`].includes(parseInt(ref))) {
          let recommendationValue = parseFloat(recommendation[pos][ref]);
          if( ref === referenceid ) {
            recommendationValue = parseFloat(normalizeAmount(value));
          }

          if (userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') {
            rapbuSummary[`total_${postype}_rekomendasi_${fieldname}`] += normalizeAmount(recommendationValue);

          } else if (userGroup === 'Manager Keuangan' || userGroup === 'Bendahara') {
            rapbuSummary[`total_${postype}_apbu_${fieldname}`] += normalizeAmount(recommendationValue);
          }
        }
      };
    }

    if(userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') {
      rapbuSummary['total_estimasi_rekomendasi'] = rapbuSummary['total_pendapatan_rekomendasi'] - rapbuSummary['total_pengeluaran_rekomendasi'];
      rapbuSummary[`total_estimasi_rekomendasi_${fieldname}`] = rapbuSummary[`total_pendapatan_rekomendasi_${fieldname}`] - rapbuSummary[`total_pengeluaran_rekomendasi_${fieldname}`];
      rapbuSummary['total_saldo_rekomendasi'] = rapbuSummary['total_pendapatan_rekomendasi'] - rapbuSummary['total_pengeluaran_rekomendasi'] - rapbuSummary['total_inventaris_rekomendasi'];
      rapbuSummary[`total_saldo_rekomendasi_${fieldname}`] = rapbuSummary[`total_pendapatan_rekomendasi_${fieldname}`] - rapbuSummary[`total_pengeluaran_rekomendasi_${fieldname}`] - rapbuSummary[`total_inventaris_rekomendasi_${fieldname}`];
    }
    if(userGroup === 'Manager Keuangan') {
      rapbuSummary['total_estimasi_apbu'] = rapbuSummary['total_pendapatan_apbu'] - rapbuSummary['total_pengeluaran_apbu'];
      rapbuSummary[`total_estimasi_apbu_${fieldname}`] = rapbuSummary[`total_pendapatan_apbu_${fieldname}`] - rapbuSummary[`total_pengeluaran_apbu_${fieldname}`];
      rapbuSummary['total_saldo_apbu'] = rapbuSummary['total_pendapatan_apbu'] - rapbuSummary['total_pengeluaran_apbu'] - rapbuSummary['total_inventaris_apbu'];
      rapbuSummary[`total_saldo_apbu_${fieldname}`] = rapbuSummary[`total_pendapatan_apbu_${fieldname}`] - rapbuSummary[`total_pengeluaran_apbu_${fieldname}`] - rapbuSummary[`total_inventaris_apbu_${fieldname}`];
    }
    this.setState(prevState => ({
      ...prevState,
      rapbuSummary,
    }));
  }

  setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  confirmReject() {
    const el = this.remarks.current.input;
    el.classList.remove('hidden');
    return swal({
      title: language.translate.ALERT__ARE_YOU_SURE,
      text: "Masukkan remarks untuk menolak RAPBU",
      content: el,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((reject) => {
      if(reject) {
        this.onReject(el.value);
      }
    });
  }


  hasRecommendations() {
    const { recommendations } = this.state;

    return Object.keys(recommendations).some((key) => {
      const items = recommendations[key];
      if(items !== null && Object.keys(items).length > 0) {
        return Object.keys(items).some((referenceid) => {
          const value = items[referenceid];
          return value !== null && value > 0;
        });
      }
      return false;
    });
  }

  renderButton() {
    const { location, user } = this.props;
    const { type } = location.state;
    const { user_groups_id, user_group } = user;
    const { workflow } = this.state;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const { is_done = false } = lastWorkflow;

    const submitButton = (
      <div key="button_submit">
        <Button
          type="button"
          onClick={this.onSubmitApproval}
          title={(user_group.name !== 'Bendahara') ? 'Submit' : 'Approve'}
        />
      </div>
    );
    const saveButton = (
      <div key="button_save">
        <Button
          type="button"
          onClick={this.onSaveRecommendations}
          title="Save"
        />
      </div>
    )
    const rejectButton = (
      <div key="button_reject">
        <Button
          type="button"
          onClick={this.confirmReject}
          title="Reject"
        />

      </div>
    )

    let buttons = [];

    if(lastWorkflow.hasOwnProperty('is_done')) {
      if(is_done) {
        return (
          <div>
            <i>RAPBU telah disetujui sebagai APBU</i>
          </div>
        );
      } else {
        if (lastWorkflow.next_role === user_group.name) {
          switch(user_group.name) {
            case 'Keuangan Sekolah':
              buttons.push(submitButton);
            break;
            case 'Bendahara Perwakilan':
              buttons.push(submitButton);
            break;
            case 'Bendahara Pusat':
              buttons.push(submitButton);
            break;
            case 'Kepala Sekolah':
              buttons.push(submitButton, rejectButton);
            break;
            case 'Korektor Perwakilan':
              buttons.push(submitButton, rejectButton);
            break;
            case 'Korektor Pusat':
              buttons.push(saveButton, submitButton, rejectButton);
            break;
			case 'Accounting Pusat':
              buttons.push(saveButton, submitButton, rejectButton);
            break;
            case 'Manager Keuangan':
              buttons.push(saveButton, submitButton, rejectButton);
            break;
            case 'Bendahara':
              buttons.push(submitButton, rejectButton);
            break;
          }

          if (user_groups_id) {
            return buttons;
          }
        } else {
          return (
            <div>
              <i>RAPBU telah disubmit ke {lastWorkflow.next_role}</i>
            </div>
          );
        }
      }
    }

    if (user_group.name === 'Keuangan Sekolah' || user_group.name === 'Bendahara Perwakilan' || user_group.name === 'Bendahara Pusat') {
      buttons.push(submitButton);
      return buttons;
    }

    return false;
  }

  renderWorkflowHistory(workflow) {
    const rows = [];

    if(workflow) {
      workflow.forEach((item, idx) => {
        const { prev_role, next_role, action, updated_at, remarks, is_done} = item;
        const roleMap = {
          'Korektor Perwakilan': 'Perwakilan',
          'Bendahara': 'Pengurus',
          'Manager Keuangan': 'Manager Keuangan',
          'Keuangan Sekolah': 'Bendahara Unit',
          'Kepala Sekolah': 'Kepala Sekolah',
        };

        let flowAction = (action === 'reject') ?
          'mengembalikan ke' : ' mengajukan ke';
        let nextRole = roleMap[next_role] ? roleMap[next_role] : next_role;
        let prevRole = roleMap[prev_role] ? roleMap[prev_role] : prev_role;
        let flowValue = `${prevRole} ${flowAction} ${nextRole}`;
        if(is_done) {
          flowValue = `RAPBU telah disetujui oleh Pengurus`;
        }
        rows.push([
          <tr key={`workflow_history_item_${idx}`}>
            <td>{idx+1}</td>
            <td>{flowValue}</td>
            <td>{remarks}</td>
            <td>{datetimeFormat(updated_at)}</td>
          </tr>
        ])

      });
      return (
        <table className="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Flow</th>
              <th>Catatan</th>
              <th>Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )
    }

    return false;
  }

  onClickPrint() {
  }

  render() {
    const {
      rapbuSummary, recommendations, workflow, remarks, budget,
    } = this.state;
    const {
      semesterOne, semesterTwo, inventaris,
    } = rapbuSummary;
    const {
      location, user, handleFileDownload,
    } = this.props;
    const { type, code } = location.state;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const isDone = lastWorkflow.is_done || false;
    let pageTitle = `${language.translate.FINANCE__BUDGETING__MANAGE_BUDGET__TITLE}
      ${language.translate.FINANCE__BUDGETING__MANAGE_BUDGET__RAPBU}
      `;
    let subTitle = budget.desc;
    let periode = budget.periode;
    if(isDone) {
      pageTitle = `${language.translate.FINANCE__BUDGETING__MANAGE_BUDGET__APBU}
      ${budget.school_unit.school_unit.name}
      `;
    }
    return (
      <div className="manage-budget">
        <form id="manage-budget-rapbu">
          <ListForm>
            <Textarea
              onChange={this.setRemarks}
              ref={this.remarks}
              classNames="hidden remarks"
              value={remarks}
            />
            <h1>
              {pageTitle}
            </h1><br/>
            <h2>{subTitle}</h2><br/>
            <h2>Periode {periode}</h2><br/>
            { isDone && (
                <BudgetApbu
                  rapbuSummary={rapbuSummary}
                  handleFileDownload={handleFileDownload}
                />
              )
            }
            { !isDone && (
                <BudgetRapbu
                  code={code}
                  rapbuSummary={rapbuSummary}
                  recommendations={recommendations}
                  onChangeRecommendation={this.onChangeRecommendation}
                  onCheckAll={this.onCheckAll}
                  onSelectBudgetDetail={this.onSelectBudgetDetail}
                  handleFileDownload={handleFileDownload}
                  user={user}
                  workflow={lastWorkflow}
                />
              )
            }
            { workflow && (
              <div className="workflow_history">
                <h2>Workflow</h2>
                {this.renderWorkflowHistory(workflow)}
              </div>
            )}
            <div className="buttons">
              {this.renderButton()}

                {  this.onClickPrint()
              }
            </div>
          </ListForm>
        </form>
      </div>
    );
  }
}
ManageBudget.propTypes = {
  handleGetBudgetDetail: PropTypes.func,
  handleSaveRecommendations: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  handleFileDownload: PropTypes.func,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
};
ManageBudget.defaultProps = {
  handleGetBudgetDetail: noop,
  handleSaveRecommendations: noop,
  handleSubmitApproval: noop,
  handleReject: noop,
  handleFileDownload: noop,
  user: null,
  history: null,
};
