import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';

export default class AdjustmentJournalForm extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
  }

  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, coa,
    } = this.props;
    return (
      <table className="manage-adjustment-journal__form-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Akun</th>
            <th>Keterangan</th>
            <th>Nominal</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`adjustment_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td>
                  <SelectCoa
                    noMargin
                    inputArray
                    arrayPosition={idx}
                    fieldName={fieldName}
                    name="code_of_account"
                    coa={coa}
                    onClick={onChange}
                    value={data.code_of_account}
                    error={error[idx] ? error[idx].code_of_account : ''}
                    rightIcon="icon-search"
                  />
                </td>
                <td>
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name={fieldName}
                    name="description"
                    onChange={onChange}
                    value={data.description || ''}
                    error={error[idx] ? error[idx].description : ''}
                  />
                </td>
                <td>
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name={fieldName}
                    data-input-type="number"
                    name="nominal"
                    onChange={onChange}
                    value={data.nominal || ''}
                    error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
          }
          <tr className="manage-adjustment-journal__add-more">
            <td colSpan={7}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
AdjustmentJournalForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  coa: PropTypes.object.isRequired,
};
AdjustmentJournalForm.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
};
